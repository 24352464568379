
import {LOAD_FILTER_ATTRIBUTES, LOAD_FILTER_PRODUCTS} from "../constants/filterConstant";

import {INTERNAL_SERVER_ERROR_CODE,BAD_REQUEST_ERROR_CODE, HANDLE_SIGN_IN_ERROR, HANDLE_SIGN_IN, AUTH_DETAILS_COOKIE, HANDLE_SIGN_OUT, HANDLE_SIGN_UP_ERROR} from "../constants/http_error_code"
import authHeader from "../services/auth-header";
import {CART_TOTAL_COOKIE, GET_ALL_CATEGORY_FAIL, GET_ALL_CATEGORY_REQUEST, GET_ALL_CATEGORY_SUCCESS, PAYMENT_RESPONSE, PAYMENT_RESPONSE_ERROR, RESET_PAYMENT_RESPONSE_ERROR, SEARCH_KEYWORD, SEARCH_KEYWORD_ERROR, SHIPPING_ADDRESS_CONFIRMED, SHOPPERS_PRODUCT_INFO_COOKIE} from "../constants/typeConstants"
import axios from "axios";
import {Base64} from 'js-base64';
import Cookies from 'js-cookie';
import { GET_ALL_PRODUCT_FAIL } from "../constants/productConstant";
import { SEARCH_SUGGESTION_API } from "../constants/api_constants";


const API_URL = "https://kaitoshop.io.vn/commondata";
// const API_URL = "http://localhost:8083/commondata";
const SEARCH_URL = "http://localhost:8084/commondata";
const LOGIN_URL = "https://kaitoshop.io.vn/api/auth";
// const LOGIN_URL = "http://localhost:8083/api/auth";

export const getDataViaAPI = (type, route, query, synchronous = true) => async dispatch => {
   
    if(route) {
        if(query) {
            route += query;
        }
    }

    let isFetchError = false;
    if(synchronous) {
        await axios.get(API_URL + route)
            .then(response => processResponse(response, query, type, route))
            .catch(err => {
                isFetchError = true;
            });
    } else {
        
        axios.get(API_URL + route)
            .then(response => processResponse(response, query, type, route, dispatch))
            .catch(err => {
                isFetchError = true;
            })
    }
    if(isFetchError) {
        dispatch({
            type: type,
            payload: {isLoading: false, statusCode: INTERNAL_SERVER_ERROR_CODE}
        })
    }
};

export const processResponse = (response, query, type, uri, dispatch) => {
  
    if(response.data != null) {
        let payload = {isLoading: false, data: JSON.parse(JSON.stringify(response.data))};
        if(query) {
            dispatch({
                type: type,
                payload: {...payload, query: query}
            });
        } else {
            dispatch({
                type: type,
                payload: payload,
            });
        }

        if(LOAD_FILTER_PRODUCTS.localeCompare(type) === 0 && 
            window.location.search.localeCompare(uri.split("/products")[1]) !== 0){
                window.location.replace(uri);
        }
    } else {
        dispatch({type: type, payload: {isLoading: false, statusCode: BAD_REQUEST_ERROR_CODE }});
    }
}

export const loadFiterAttributes = (filterQuery) => dispatch => {
    if(filterQuery) {
        let uri = `/filter${filterQuery}`;
        axios.get(API_URL +uri)
            .then((response) => {
                dispatch({
                    type: LOAD_FILTER_ATTRIBUTES,
                    payload: JSON.parse(JSON.stringify({
                        ...response.data,
                        "query": filterQuery.split(3)
                    }))
                });

                return JSON.parse(JSON.stringify(response.data));
            }).catch(e => {
                console.log(e);
            });
    }
}

export const setShippingAddress = (payload)  => {
    return ({
        type: SHIPPING_ADDRESS_CONFIRMED,
        payload: payload
    })
}

export const sendPaymentToken = (token) => async dispatch => {
    if(!token || (token && !token.hasOwnProperty("id"))) {
        dispatch({
            type: PAYMENT_RESPONSE_ERROR,
            payload: {errorMsg: "Unable to fetch token. Try again later"},
        });
    }
    const url = API_URL + "/payment";
    let config = {
        method: 'post',
        url: url,
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(token)
    };

     axios(config)
        .then(function (response) {
            let paymentResponse = {
                ...response.data,
                last4: token.card.last4,
                exp_year: token.card.exp_year,
                exp_month: token.card.exp_month,
                brand: token.card.brand,
            }
          
            if(paymentResponse.payment_failed) {
                window.location.replace(`/checkout/cancel-payment/${response.data.charge_id}`)
            } else {
                window.location.replace(`/checkout/success-payment/${response.data.charge_id}`);
                Cookies.remove(CART_TOTAL_COOKIE);
                Cookies.remove(SHOPPERS_PRODUCT_INFO_COOKIE);
            }   

            dispatch({
                type: PAYMENT_RESPONSE,
                payload: {...paymentResponse, error: false, errorMsg: null}
            });
        })
        .catch(function(error) {
            dispatch({
                type: RESET_PAYMENT_RESPONSE_ERROR,
                payload: {errorMsg: "Something Went Wrong"}
            });
        });
}

export const getAllCategories = () => async(dispatch) => {
    

    try {
        dispatch({ type: GET_ALL_CATEGORY_REQUEST });

        const { data } = await axios.get(API_URL + "/getAllCategories");
    

        dispatch({
            type: GET_ALL_CATEGORY_SUCCESS,
            payload: data
        })
    } catch(error) {
        dispatch({
            type: GET_ALL_PRODUCT_FAIL,
            payload: error
        })
    }
}

export const setDefaultSearchSuggestion = () => dispatch => {
    axios.get(SEARCH_URL + "/default-search-suggestion")
        .then(response => {
            dispatch({
                type: SEARCH_KEYWORD, payload: {data: JSON.parse(JSON.stringify(response.data))}
            });
        })
        .catch(err => {
            dispatch({type: SEARCH_KEYWORD_ERROR})
        })
}

export const getSearchSuggestions = (prefix) => async dispatch => {
    if(prefix) {
        let responseError = false;
        const uri = SEARCH_SUGGESTION_API + prefix;
        const response = await axios.get(SEARCH_URL + uri)
            .catch((err) => {
                dispatch({type: SEARCH_KEYWORD_ERROR});
                responseError = true;
            });
        if(responseError){
            return;
        }

        dispatch({
            type: SEARCH_KEYWORD,
            payload: {data: JSON.parse(JSON.stringify(response.data))},
        });
        
    }
}

export const signIn = (formValues) => async dispatch => {
    const hash = Base64.encode(`${formValues.username}:${formValues.password}`);

    axios.defaults.headers.common['Authorization'] = `Basic ${hash}`;
    const response = await axios.post(LOGIN_URL + '/authenticate').catch(err => {
        dispatch({
            type: HANDLE_SIGN_IN_ERROR,
            payload: err.message
        })
    })

    if (response) {
        if (response.data.jwt) {
            
            dispatch({type: HANDLE_SIGN_IN, payload: JSON.parse(JSON.stringify(response.data))});
            Cookies.set(AUTH_DETAILS_COOKIE, JSON.stringify(response.data), {expires: 2});
            window.location.replace('/');
            // console.log(Cookies.get(AUTH_DETAILS_COOKIE));
        } else {
           
            dispatch({type: HANDLE_SIGN_IN_ERROR, payload: response.data.error});
        }
    }
}

export const signOut = () => {
    Cookies.remove(AUTH_DETAILS_COOKIE);
    return {
        type: HANDLE_SIGN_OUT,
    }
}

export const signUp = (formValues) => async dispatch => {
    console.log(formValues);
    const response = await axios.post(LOGIN_URL + "/signup",{
        'username': formValues.username,
        'password': formValues.password,
        'firstName': formValues.firstName,
        'lastName': formValues.lastName,
        'email': formValues.email.toLowerCase(),
    }).catch(err => {
        dispatch({
            type: HANDLE_SIGN_UP_ERROR,
            payload: err.message,
        })
    });

    if(response) {
        if(response.data.account_creation_status === 'success') {
            window.location.replace("/signin")
        } else {
            dispatch({
                type: HANDLE_SIGN_UP_ERROR,
                payload: response.data.error_msg
            });
        }
    }
}

export const setAuthDetailsFromCookie = (savedResponse) => {
    return {
        type: HANDLE_SIGN_IN,
        payload: savedResponse,
    }
}