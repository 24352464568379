import React, { useEffect, useState, } from 'react';
import { Button, Grid, Box, Typography } from "@material-ui/core";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';


import BreadcrumbsSection from "../../components/Product/breadCrumps";
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';
import { getDataViaAPI } from '../../actions/CommonAction';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import Cookies from 'js-cookie';
import { ADD_TO_CART } from "../../constants/cartConstant";
import { SELECT_PRODUCT_DETAIL } from "../../constants/filterConstant";

import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "../../components/Product/spinner";


import { InternalServerError } from "../../components/error/internalServerError";
import { BadRequest } from "../../components/error/badRequest";
import _ from "lodash";
import { PRODUCT_BY_ID_DATA_API } from "../../constants/api_constants";
import { SHOPPERS_PRODUCT_INFO_COOKIE } from "../../constants/typeConstants";
import { HOME_ROUTE } from "../../constants/filterConstant";
import { DocumentTitle } from "../../components/Product/documentTitle";
import { useLocation, useNavigate } from 'react-router-dom';
import { Rating } from "@material-ui/lab";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AUTH_DETAILS_COOKIE } from "../../constants/http_error_code";
import { createReviews } from "../../actions/ReviewAction";
import ReviewCard from "./ReviewCard.js";
import "./details.css";
import { getReviewsById } from "../../actions/ReviewAction";

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";

export const useButtonStyles = makeStyles(() => ({
    buttonStartIcon: {
        margin: 0,
    },
    carouselImage: {
        minWidth: "220px",
        height: "320px",
        display: "flex"
    }
}));

function ProductDetails(props) {
    const classes = useButtonStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const selectProductDetail = useSelector(state => state.selectProductDetailReducer);
    // const {success, error: reviewError} = useSelector((state) => state.newReviewReducer);
    const { reviews } = useSelector(state => state.getAllReviewsReducer);

    const selectedProduct = selectProductDetail.hasOwnProperty("data") ?
        selectProductDetail.data[location.search.split("product_id=")[1]] : null;
    // const user = JSON.parse(Cookies.get(AUTH_DETAILS_COOKIE));

    const dispatch = useDispatch();
    const addToCart = useSelector(state => state.addToCartReducer);
    const [productQuantity, setProductQuantity] = useState(1);
    const [value, setValue] = useState("description");
    const [open, setOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");

    const productId = location.search.split("product_id")[1];
    // const selectedProduct = null;
    let user = null;
    if(Cookies.get(AUTH_DETAILS_COOKIE) !== undefined) {
        user = JSON.parse(Cookies.get(AUTH_DETAILS_COOKIE));
    }


    /**
     * Update the Component when product is already selected.
     * Cart products are stored in Cookie and we will set the product
     * for the first time the page is rendered.
     */
    useEffect(() => {

        if (selectedProduct && !_.isEmpty(addToCart.productQty)) {
           
            if(addToCart.productQty[selectedProduct.id]) {
                setProductQuantity(addToCart.productQty[selectedProduct.id])
            }

        }
    }, [selectedProduct]);

    useEffect(() => {
        dispatch(getReviewsById(location.search.split("product_id=")[1]));
    }, [open])

    // if the user change the URL format then just return bad request.
    if (location.pathname.localeCompare('/products/details') !== 0 ||
        location.search.search('product_id=') === -1
        || !location.search.startsWith('?q=')) {
        return <BadRequest />
    }

    // find the breadcrumb from the url
    const getStringBeforeLastDelimiter = (str, delimiter) => {
        return str.substring(0, str.lastIndexOf(delimiter))
    }

    const breadcrumbLinks = [
        {
            name: 'Home',
            link: HOME_ROUTE
        },
        {
            name: 'Products',
            link: `${getStringBeforeLastDelimiter(location.pathname, "/")
                + getStringBeforeLastDelimiter(location.search, "::")}`
        },
        {
            name: 'Details',
            link: `${location.pathname + location.search}`
        },
    ]



    if (!selectedProduct) {
        try {

            // if status code is set then its because of error.
           
            if (selectProductDetail.hasOwnProperty("statusCode")) {
               
                return <InternalServerError/>
            }

            // get the product details from server
            const extractedProductId = location.search.split("product_id=")
           
            if (extractedProductId.length === 2) {
                props.getDataViaAPI(SELECT_PRODUCT_DETAIL, PRODUCT_BY_ID_DATA_API + extractedProductId[1], null, false)
            }
        } catch (e) {
           
            return <BadRequest/>
        }
    }


    if (selectProductDetail.isLoading) {
        return <Spinner/>
    } else {
        if (!selectedProduct) {
            return <BadRequest/>
        }
    }

    // set the cart products in the cookie
    const dispatchAddToCart = newAddToCart => {
        Cookies.set(SHOPPERS_PRODUCT_INFO_COOKIE, JSON.stringify(newAddToCart), { expires: 7 });

        dispatch({
            type: ADD_TO_CART,
            payload: newAddToCart
        })
    }

    const submitReviewToggle = () => {
        open ? setOpen(false) : setOpen(true);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const reviewSubmitHandler = () => {
        const myForm = new FormData();

        myForm.set("ratings", rating);
        myForm.set("comment", comment);
        myForm.set("productId", selectedProduct.id);
        myForm.set("userId", user.userInfo.id);
        dispatch(createReviews(myForm));
        setOpen(false);
    }

    const handleAddToBagBtnClick = product_id => () => {

        let newAddToCart = addToCart

        // add new product to cart
        if (newAddToCart.hasOwnProperty("productQty") === false) {
            newAddToCart = {
                totalQuantity: productQuantity,
                productQty: {
                    [product_id]: productQuantity
                }
            }
        } else {
            let totalQuantity = 0
            newAddToCart.productQty[product_id] = productQuantity
            newAddToCart.totalQuantity = 0

            for (const [, qty] of Object.entries(newAddToCart.productQty)) {
                totalQuantity += qty
            }
            newAddToCart.totalQuantity += totalQuantity
        }
        dispatchAddToCart(newAddToCart)
    }

    /**
     * Navigate to shopping bag page.
     */
    const handleProceedToBagBtnClick = () => {
        navigate(`${location.pathname}/shopping-bag${location.search}`)
    }

    if (props.window) {
        props.window.scrollTo(0, 0)
    }

    // const options = {
    //     size: "large",
    //     value: selectedProduct.ratings,
    //     readOnly: true,
    //     precision: 0.5,
    // };

    // console.log("SelectedProduct", selectProductDetail.data[productId]);

    return (
        <>
            <DocumentTitle title="Product Details" />
            <Box display="flex" p={3}>
                <BreadcrumbsSection linkList={breadcrumbLinks} />
            </Box>

            <Grid container>

                <Grid item container justify="center" sm={10} md={4} lg={4}>
                    {/* <img src={selectedProduct.imageURL} alt={selectedProduct.name}
                        style={{ height: "70%", width: "70%", paddingBottom: "0rem" }}
                        title={selectedProduct.name} /> */}
                    {/* <div className='carousel-container'>
                    <Carousel>
                     

                        {selectedProduct.productImages &&
                            selectedProduct.productImages.map((item, i) => (
                                <img
                                   
                                    key={i}
                                    src={item.imageUrl}
                                    alt={`${i} Slide`}
                                    style={{ maxWidth: "430px", paddingBottom: "0rem" }}
                                />
                            ))}

                    </Carousel>
                    </div> */}


{/* 
                    <ImageList variant="masonry" cols={3} gap={8}>
                        {selectedProduct.productImages &&
                            selectedProduct.productImages.map((item) => (
                                <ImageListItem key={item.img}>
                                    <img
                                        srcSet={`${item.imageUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${item.imageUrl}?w=248&fit=crop&auto=format`}

                                        loading="lazy"
                                    />
                                    <ImageListItemBar position="below" title="DDN" />
                                </ImageListItem>
                            ))}
                    </ImageList> */}

                    <ImageList sx={{ marginLeft: 2, width: 500, height: 350 }} cols={3} rowHeight={164}>
                        {selectedProduct.productImages &&
                            selectedProduct.productImages.map((item) => (
                            <ImageListItem key={item.imageUrl}>
                                <img
                                    srcSet={`${item.imageUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${item.imageUrl}?w=164&h=164&fit=crop&auto=format`}
                                   
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>

                </Grid>

                <Grid item xs={11} sm={5} md={6} container direction={"column"} spacing={2}
                    style={{ marginLeft: "1rem" }}>
                    {/* <Grid item style={{fontSize: "2rem", fontWeight: "bolder"}}>
                        {selectedProduct.productBrandCategory.type}
                    </Grid> */}

                    <Grid item style={{ fontSize: "1.7rem", fontWeight: 600, paddingTop: "1rem" }}>
                        {selectedProduct.name}
                    </Grid>

              





                    <Grid item style={{ fontSize: "1.8rem", fontWeight: 600, paddingTop: "1rem" }}>
                        {`$ ${selectedProduct.price}`}
                    </Grid>

                    <Grid item style={{ fontSize: "1.8rem", fontWeight: 600, paddingTop: "1rem" }}>
                        {selectedProduct.availableQuantity < 1 ? "Het Hang" : "Con Hang"}
                    </Grid>

                    <Grid item style={{ fontSize: "1rem", fontWeight: 700, color: "green" }}>
                        inclusive of all taxes
                    </Grid>

                    <Grid item style={{ fontSize: "1.8rem", fontWeight: 600, paddingTop: "1rem" }}>
                        <button onClick={submitReviewToggle} className="submitReview">
                            Submit Review
                        </button>
                    </Grid>

                    <Grid item container alignItems="center">
                        <Grid item style={{ fontSize: '1.2rem', fontWeight: "lighter", paddingRight: 10 }}>
                            Qty:
                        </Grid>

                        <Grid item style={{ fontSize: '1.2rem', fontWeight: "bold", paddingRight: 20 }}>
                            {productQuantity}
                        </Grid>

                        <Grid item>
                            <Button variant="outlined" color="primary" size="large"
                                style={{ height: 40 }}
                                classes={{ startIcon: classes.buttonStartIcon }}
                                startIcon={<RemoveIcon fontSize="large" />}
                                disabled={productQuantity === 1}
                                onClick={() => setProductQuantity(productQuantity - 1)}
                            >
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button variant="outlined" color="primary" size="large"
                                style={{ height: 40 }}
                                classes={{ startIcon: classes.buttonStartIcon }}
                                startIcon={<AddIcon fontSize="large" />}
                                onClick={() => setProductQuantity(productQuantity + 1)}
                            >
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={8} md={4}>
                            <Button
                                style={{
                                    height: 44, color: 'white',
                                    fontWeight: "bold", backgroundColor: "#AB0000"
                                }}
                                fullWidth
                                startIcon={<AddShoppingCartIcon />}
                                onClick={handleAddToBagBtnClick(selectedProduct.id)}
                            >
                                ADD TO BAG
                            </Button>
                        </Grid>

                        <Grid item xs={12} sm={8} md={5}>
                            <Button variant="outlined" size="large" color="default"
                                style={{ height: 44, fontWeight: "bold" }}
                                fullWidth
                                startIcon={<LocalMallIcon />}
                                onClick={handleProceedToBagBtnClick}
                            >
                                PROCEED TO BAG
                            </Button>
                        </Grid>

                    </Grid>

                </Grid>

            </Grid>

            <Box mt="20px" width="100%" justifyContent="space-between" display="flex">
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="DESCRIPTION" value="description" variant="h3" fontWeight="bold" />
                    <Tab label="REVIEWS" value="reviews" />
                </Tabs>
            </Box>
            <Box display="flex" flexWrap="wrap" >
                {value === "description" && (
                    <div>description</div>
                )}
                {value === "reviews" &&
                    <div>
                        {reviews && reviews[0] ? (
                            <div className="reviews">
                                {reviews &&
                                    reviews.map((review) => (
                                        <ReviewCard key={review.id} review={review} />
                                    ))}
                            </div>
                        ) : (
                            <p className='noReviews'>No Reviews</p>
                        )}
                    </div>}
            </Box>

            {/* RELATED ITEMS */}
            <Box mt="50px" width="100%">
                <Typography variant="h3" fontWeight="bold">
                    Related Products
                </Typography>
                <Box
                    mt="20px"
                    display="flex"
                    flexWrap="wrap"
                    columnGap="1.33%"
                    justifyContent="space-between"
                >
                    {/* {items.slice(0, 4).map((item, i) => (
                        <Item key={`${item.name}-${i}`} item={item} />
                    ))} */}
                </Box>
            </Box>

            <Dialog
                aria-labelledby="simple-dialog-title"
                open={open}
                onClose={submitReviewToggle}
            >
                <DialogTitle>Submit Review</DialogTitle>
                <DialogContent className="submitDialog">
                    <Rating
                        onChange={(e) => setRating(e.target.value)}
                        value={rating}
                        size="large"
                    />

                    <textarea
                        className="submitDialogTextArea"
                        cols="30"
                        rows="5"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                </DialogContent>
                <DialogActions>
                    <Button onClick={submitReviewToggle} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={reviewSubmitHandler} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            {/* {reviews && reviews[0] ? (
                <div className="reviews">
                    {reviews &&
                        reviews.map((review) => (
                            <ReviewCard key={review.id} review={review} />
                        ))}
                </div>
            ) : (
                <p className='noReviews'>No Reviews</p>
            )}  */}
            {/* <Box m="20px 20px 20px 20px">
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="DESCRIPTION" value="description" />
                    <Tab label="REVIEWS" value="reviews" />
                </Tabs>
            </Box>
            <Box display="flex" flexWrap="wrap" gap="15px">
                {value === "description" && (
                    <div>description</div>
                )}
                {value === "reviews" && <div>reviews</div>}
            </Box> */}
        </>
    );
}

export default connect(null, { getDataViaAPI })(ProductDetails);