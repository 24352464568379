export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";


export const ALL_USERS_REQUEST = "ALL_USERS_REQUEST";
export const ALL_USERS_SUCCESS = "ALL_USERS_SUCCESS";
export const ALL_USERS_FAIL = "ALL_USERS_FAIL";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_RESET = "UPDATE_USER_RESET";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";


export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_RESET = "DELETE_USER_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const TAB_CONFIG = [
    {index: 0, label: 'MEN', color: '#ee5f73', mapKey: "men"},
    {index: 1, label: 'WOMEN', color: '#fb56c1', mapKey: "women"},
    {index: 2, label: 'KIDS', color: '#f26a10', mapKey: "boys"},
    {index: 3, label: 'ESSENTIALS', color: '#0db7af', mapKey: "essentials"},
    {index: 4, label: 'HOME & LIVING', color: '#f2c210', mapKey: "homeAndLiving"},
];

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'];
export const TABS_API_OBJECT_LEN = 6;
export const HANDLE_TAB_HOVER_EVENT = "HANDLE_TAB_HOVER_EVENT";

export const SEARCH_KEYWORD = "SEARCH_KEYWORD";
export const SEARCH_KEYWORD_ERROR = "SEARCH_KEYWORD_ERROR";

export const DELIVERY_CHARGES = "DELIVERY_CHARGES";
export const RESET_DELIVERY_CHARGES = "RESET_DELIVERY_CHARGES";
export const SHIPPING_OPTION_CONFIRMED = "SHIPPING_OPTION_CONFIRMED";

export const CART_TOTAL = "CART_TOTAL";
export const RESET_CART_TOTAL = "RESET_CART_TOTAL";
export const CART_TOTAL_COOKIE = "CART_TOTAL_COOKIE";
export const LOAD_SHOPPING_BAG_PRODUCTS = "LOAD_SHOPPING_BAG_PRODUCTS";
export const RESET_PAYMENT_RESPONSE_ERROR = "RESET_PAYMENT_RESPONSE_ERROR";
export const SHIPPING_ADDRESS_CONFIRMED = "SHIPPING_ADDRESS_CONFIRMED";
export const SHOPPERS_PRODUCT_INFO_COOKIE = 'SHOPPERS_PRODUCT_INFO_COOKIE';
export const PAYMENT_RESPONSE_ERROR = "PAYMENT_RESPONSE_ERROR";
export const PAYMENT_RESPONSE = "PAYMENT_RESPONSE";
export const ADD_TO_CART = "ADD_TO_CART";
export const RESET_SHOPPING_BAG_PRODUCTS = "RESET_SHOPPING_BAG_PRODUCTS";
export const INITIAL_SHIPPING_ADDRESS_STATE = {
    values: null,
    submitted: false
}
export const RESET_SHIPPING_ADDRESS = "RESET_SHIPPING_ADDRESS";


export const RESET_PAYMENT_RESPONSE = "RESET_PAYMENT_RESPONSE";
export const INITIAL_SHIPPING_OPTION_STATE = {
    price: "Free",
    submitted: false
}
export const RESET_SHIPPING_OPTION = "RESET_SHIPPING_OPTION";
export const PAYMENT_INFO_CONFIRMED = "PAYMENT_INFO_CONFIRMED";
export const RESET_ADD_TO_CART = "RESET_ADD_TO_CART";


export const GET_ALL_CATEGORY_REQUEST = "GET_ALL_CATEGORY_REQUEST";
export const GET_ALL_CATEGORY_SUCCESS = "GET_ALL_CATEGORY_SUCCESS";
export const GET_ALL_CATEGORY_FAIL = "GET_ALL_CATEGORY_FAIL";

