import React, {useEffect, useState} from 'react';
import RadioButtonsGroup from "./radioButtonGroup";
import {useSelector} from "react-redux";
import {Grid} from "@material-ui/core";
import {NavBarHeader} from "./navBarHeaders";
import history from "../../../helper/history";
import { useNavigate } from "react-router-dom";

export default function GenderRadioButton() {
    const navitigate = useNavigate();
    const genderList = useSelector(state => state.filterAttributesReducer.data ?
        state.filterAttributesReducer.data.genders : null);
    const resetFilter = useSelector(state => state.clearFiltersReducer);
    const selectedGenders = useSelector(state => state.selectedFilterAttributesReducer.genders);
    const [selectedValue, setSelectedValue] = useState(false);



 
    

    
    useEffect(() => {
        if(selectedGenders.length > 0) {
            
            setSelectedValue(selectedGenders[0].value);
        } else {
            setSelectedValue(false);
        }
    }, [selectedGenders]);

    useEffect(() => {
        if(resetFilter) {
            if(selectedValue !== false) {
                setSelectedValue(false);
            }
        }
    }, [resetFilter]);

    if(!genderList) {
        return null;
    }

    const handleRadioButtonChange = value => {
        for(let i = 0; i < genderList.length; i++) {
            if (value.charAt(0).localeCompare(genderList[i].value.charAt(0)) === 0) {
               
                setSelectedValue(value)
                let route = history.location.pathname
                let queryStr = history.location.search
                if(history.location.search.search("genders") === -1) {
                    navitigate(`${route}${queryStr}::genders=${genderList[i].id}`)
                } else {
                    navitigate(`${route}${queryStr.replace(/genders=[0-9]/gi, `genders=${genderList[i].id}`)}`)
                }
                return;
            }
        }

        window.scrollTo(0,80);
    }

    return (
        <>
            <Grid item style={{padding: "1rem 0"}}>
                <NavBarHeader title="Gender"/>
            </Grid>
            <Grid item style={{marginLeft: "0.5rem"}}>
                <RadioButtonsGroup onChangeHandler={handleRadioButtonChange}
                                   attrList={genderList.filter(obj => obj.id < 7)}
                                   selectedValue={selectedValue}
                                   title="Gender"/>
            </Grid>
        </>
    );
}