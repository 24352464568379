export const NEW_REVIEWS_SUCCESS = "NEW_REVIEWS_SUCCESS";
export const NEW_REVIEWS_REQUEST = "NEW_REVIEWS_REQUEST";
export const NEW_REVIEWS_FAIL = "NEW_REVIEWS_FAIL";

export const NEW_REVIEWS_RESET = "NEW_REVIEWS_RESET";


export const UPDATE_REVIEWS_REQUEST = "UPDATE_REVIEWS_REQUEST";

export const UPDATE_REVIEWS_RESET = "UPDATE_REVIEWS_RESET";
export const UPDATE_REVIEWS_FAIL = "UPDATE_REVIEWS_FAIL";
export const UPDATE_REVIEWS_SUCCESS = "UPDATE_REVIEWS_SUCCESS";


// export const GET_ALL_CATEGORY_REQUEST = "GET_ALL_CATEGORY_REQUEST";
// export const GET_ALL_CATEGORY_SUCCESS = "GET_ALL_CATEGORY_SUCCESS";
// export const GET_ALL_CATEGORY_FAIL = "GET_ALL_CATEGORY_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const DELETE_REVIEWS_REQUEST = "DELETE_REVIEWS_REQUEST";
export const DELETE_REVIEWS_RESET = "DELETE_REVIEWS_RESET";
export const DELETE_REVIEWS_FAIL = "DELETE_REVIEWS_FAIL";
export const DELETE_REVIEWS_SUCCESS = "DELETE_REVIEWS_SUCCESS";

export const GET_ALL_REVIEWS_SUCCESS = "GET_ALL_REVIEWS_SUCCESS";
export const GET_ALL_REVIEWS_FAIL = "GET_ALL_REVIEWS_FAIL";
export const GET_ALL_REVIEWS_REQUEST = "GET_ALL_REVIEWS_REQUEST";

export const GET_REVIEWS_DETAIL_SUCCESS = "GET_REVIEWS_DETAIL_SUCCESS";
export const GET_REVIEWS_DETAIL_FAIL = "GET_REVIEWS_DETAIL_FAIL";
export const GET_REVIEWS_DETAIL_REQUEST = "GET_REVIEWS_DETAIL_REQUEST"