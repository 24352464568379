import { CLEAR_ERRORS } from "../constants/productConstant";
import { GET_ALL_REVIEWS_SUCCESS, GET_REVIEWS_DETAIL_SUCCESS, NEW_REVIEWS_FAIL, NEW_REVIEWS_REQUEST, NEW_REVIEWS_RESET, NEW_REVIEWS_SUCCESS } from "../constants/reviewConstant";

export const getAllReviewsReducer = (state = {reviews: []}, action) => {
    if(action.type === GET_REVIEWS_DETAIL_SUCCESS) {
        return {
            ...state,
            reviews: action.payload,
        };
    }
    return state;
}

// export const reviewDetailReducer = (state = { review: {}}, action) => {
//     if(action.type === GET_REVIEWS_DETAIL_SUCCESS) {
//         return {
//             ...state,
//             review: action.payload
//         }
//     }
// }

export const newReviewReducer = (state = {}, action) => {
    switch(action.type) {
        case NEW_REVIEWS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case NEW_REVIEWS_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
            };
        case NEW_REVIEWS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case NEW_REVIEWS_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

