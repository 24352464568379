import React from 'react';

import {useDispatch, useSelector} from "react-redux";
import {CLEAR_ALL_FILTERS, RESET_SELECTED_CATEGORY} from "../../../constants/filterConstant";
import history from "../../../helper/history";
import { useNavigate, useLocation } from "react-router-dom";

function ClearAllButton() {
    const navitigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const selectedFilterAttribute = useSelector(state => state.selectedFilterAttributesReducer);

    // check if any filter is selected or not
    if ((selectedFilterAttribute.genders.length + selectedFilterAttribute.apparels.length
        + selectedFilterAttribute.brands.length + selectedFilterAttribute.prices.length) === 0) {
        return null;
    }

    /**
     * remove all selected filters
     */
    const handleClearAllClick = () => {
        
        dispatch({
            type: RESET_SELECTED_CATEGORY
        });
        dispatch({
            type: CLEAR_ALL_FILTERS,
            payload: true
        });
        let queryString = location.pathname + "?q=page=0,16"
        navitigate(queryString);
    }

    return (
        <>
            <div onClick={handleClearAllClick} style={{
                fontWeight: "bold", cursor: 'pointer',
                fontSize: '0.9rem', height: 'inherit', color: 'red'
            }}>CLEAR ALL
            </div>
        </>
    );
}

export default ClearAllButton;