import React, { Fragment, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import "./productList.css";
import { useSelector, useDispatch } from "react-redux";

import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import MetaData from "../../layout/MetaData";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SideBar from "./Sidebar";
// import { getAllUsers, clearErrors, deleteUser } from "../../actions/userAction";
import { DELETE_USER_RESET } from "../../constants/typeConstants";
import { clearErrors, deleteUser, getAllUsers } from "../../actions/AuthAction";
import { useNavigate, NavLink } from 'react-router-dom';


const UserList = () => {
    const dispatch = useDispatch();
    const alert = useAlert();
    const navitigate = useNavigate();

    const { error, users } = useSelector((state) => state.allUsers);
    const {
        error: deleteError,
        isDeleted,
        message
    } = useSelector((state) => state.profile);

    const deleteUserHandler = (id) => {
        dispatch(deleteUser(id));
    }

    useEffect(() => {
        if(error) {
            alert.error(error);
            dispatch(clearErrors());
        }

        if(deleteError) {
            alert.error(deleteError);
            dispatch(clearErrors());
        }

        if(isDeleted){
            alert.success(message);
            navitigate.push("/admin/users");
            dispatch({ type: DELETE_USER_RESET });
        }

        dispatch(getAllUsers());
    }, [dispatch, alert, error, deleteError, isDeleted, message]);

    const columns = [
        { field: "id", headerName: "User ID", minWidth: 180, flex: 0.8 },
        {
            field: "email",
            headerName: "Email",
            minWidth: 200,
            flex: 1
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 150,
            flex: 0.5
        },
        {
            field: "role",
            headerName: "Role",
            type: " number",
            minWidth: 150,
            flex: 0.3,
            cellClassName: (params) => {
                return params.getValue(params.id, "role") === "admin"
                    ? "greenColor"
                    : "redColor"
            },
        },
        {
            field: "actions",
            flex: 0.3,
            headerName: "Actions",
            minWidth: 150,
            type: "number",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        <NavLink to={`/admin/user/${params.getValue(params.id, "id")}`}>
                            <EditIcon />
                        </NavLink>

                        <Button
                            onClick={() =>
                                deleteUserHandler(params.getValue(params.id, "id"))
                            }
                        >
                            <DeleteIcon />
                        </Button>
                    </Fragment>
                );
            },
        },
    ];

    const rows = [];

    users &&
        users.forEach((item) => {
            rows.push({
                id: item.id,
                role: item.roles[0].name,
                email: item.email,
                name: item.username
            });
        });
    
    return (
        <Fragment>
            <MetaData title={`ALL USERS - Admin`} />

            <div className="dashboard">
                <SideBar />
                <div className="productListContainer">
                    <h1 id="productListHeading">All Users</h1>
                    <DataGrid 
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                        className="productListTable"
                        autoHeight
                    />

                </div>
            </div>
        </Fragment>
    );
};
export default UserList;