import {
  GET_ALL_PRODUCT_SUCCESS,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  NEW_PRODUCT_SUCCESS,
  NEW_PRODUCT_REQUEST,
  NEW_PRODUCT_FAIL,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  CLEAR_ERRORS
} from "../constants/productConstant.js";
import axios from "axios";
import authHeader from "../services/auth-header";
import { LOAD_FILTER_PRODUCTS } from '../constants/filterConstant';

const API_URL = "https://kaitoshop.io.vn/commondata/";
// const API_URL = "http://localhost:8083/commondata/";

const fetchSuccess = (products) => {
  return {
    type: GET_ALL_PRODUCT_SUCCESS,
    payload: products,
  };
};

// Get All Products
export const getProduct = () => {
  return (dispatch) => {

    axios.get(API_URL)
      .then((response) => {
        dispatch(fetchSuccess(response.data));
      });
  }
}

export const getProductDetails = (id) => {
  return (dispatch) => {
    axios.get(API_URL + id, { headers: authHeader() })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_DETAIL_SUCCESS,
          payload: response.data
        });
      })
  }
}


export const createProduct = (productData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRODUCT_REQUEST });

  

    const { data } = await axios.post(
      API_URL + "add",
      productData
    );

    dispatch({
      type: NEW_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

//update product
export const updateProduct = (id, productData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });

    const { data } = await axios.post(API_URL + "update/" + id,JSON.stringify(productData), { headers: authHeader() });

    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: data.success
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

//delete product
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCT_REQUEST });

    const { data } = await axios.delete(API_URL + "delete/" + id, { headers: authHeader() });

    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: data.success
    })
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload: error.response.data.message
    });
  }
};

//clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS
  });
};

export const loadProductDisplay = (queryString) => async (dispatch) => {
  try {
    
    // const API_URL1 = "https://kaitoshop.io.vn/commondata/products";
    const API_URL1 = "http://localhost:8083/commondata/products";
    const { data } = await axios.get(API_URL1 + queryString);
    console.log("Da vao product Action");
    console.log(data);
    dispatch({
      type: LOAD_FILTER_PRODUCTS,
      payload: data.response
    })
  } catch (error) {
    console.log(error);
  }
}