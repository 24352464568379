import React, { Fragment, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import MetaData from "./MetaData";

import { useNavigate, Link } from "react-router-dom";
import "./Profile.css";
import {signOut, setAuthDetailsFromCookie} from "../../actions/CommonAction";
import Cookies from 'js-cookie';
import {AUTH_DETAILS_COOKIE} from "../../constants/http_error_code"

const Profile = (props) => {
  const navigate = useNavigate();

  const user = JSON.parse(Cookies.get(AUTH_DETAILS_COOKIE));
  // useEffect(() => {
  //   if (isAuthenticated === false) {
  //     history.push("/login");
  //   }
  // }, [history, isAuthenticated]);
  if (user !== null) {
    navigate("/signin");
  }

  const signOutProfile = () => {
    props.signOut();
    navigate("/signin");
  }

console.log(user.userInfo);


  return (

    <Fragment>
      <MetaData title={`${user.userInfo.username}'s Profile`} />
      <div className="profileContainer">
        <div>
          <h1>My Profile</h1>
          {/* <img src={user.avatar.url} alt={user.name} /> */}
          <Link to="/me/update">Edit Profile</Link>
        </div>
        <div>
          <div>
            <h4>Full Name</h4>
            <p>{user.userInfo.firstName + " " + user.userInfo.lastName}</p>
          </div>
          <div>
            <h4>Email</h4>
            <p>{user.userInfo.email}</p>
          </div>
          <div>
            <h4>Joined On</h4>
            <p>{String(user.userInfo.createdAt).substr(0, 10)}</p>
          </div>

          <div>
            <Link to="/orders">My Orders</Link>
            <Link to="/password/update">Change Password</Link>
            <button onClick={() => signOutProfile()} >Sign Out</button>
          </div>
        </div>
      </div>
    </Fragment>

  );
};


export default connect(null, {
  setAuthDetailsFromCookie, signOut
})(Profile);