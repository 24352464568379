import axios from "axios";

import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    ALL_USERS_REQUEST,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    CLEAR_ERRORS,
    LOAD_USER_REQUEST
  } from "../constants/typeConstants";
  import authHeader from "../services/auth-header";
  
  import AuthService from "../services/auth.service";

  const API_URL = "https://kaitoshop.io.vn/api/auth/";
  // const API_URL = "http://localhost:8083/api/auth/";
  
  export const register = (username, email, password) => (dispatch) => {
    return AuthService.register(username, email, password).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: REGISTER_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  
  export const login = (username, password) => (dispatch) => {
    return AuthService.login(username, password).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: LOGIN_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  
  export const logout = () => (dispatch) => {
    AuthService.logout();
  
    dispatch({
      type: LOGOUT,
    });
  };

//get All Users
export const  getAllUsers = () => async(dispatch) => {
  try {
    dispatch({ type: ALL_USERS_REQUEST });

    const { data } = await axios.get(API_URL + "allUser", { headers: authHeader() });
    

    dispatch({ type: ALL_USERS_SUCCESS, payload: data});
  } catch(error) {
    dispatch({
      type: ALL_USERS_FAIL,
      payload: error.response.data.message
    })
  }
}



//update User
export const updateUser = (id, userData) => async(dispatch) => {
  try {
    dispatch({
      type: UPDATE_USER_REQUEST
    });

    const { data } = await axios.post(API_URL + "updateUser/" + id,JSON.stringify(userData), { headers: authHeader() });

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data.success,
    });
    
  } catch(error) {
      dispatch({
        type: UPDATE_USER_FAIL,
        payload: error.response.data.message
      });
  }
};

export const deleteUser = (id) => async(dispatch) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });

    const { data } = await axios.delete(API_URL + "delete/" + id, { headers: authHeader()});

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: data
    });
  } catch(error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: error.response.data.message
    });
  }
};

export const clearErrors = () => async(dispatch) => {
  dispatch({ type: CLEAR_ERRORS});
};