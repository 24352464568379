import React, { Component } from 'react'
import StripeCheckout from 'react-stripe-checkout';
import { Button, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { sendPaymentToken } from "../../actions/CommonAction"
import Cookies from 'js-cookie';
import { AUTH_DETAILS_COOKIE } from "../../constants/http_error_code"

import AlertModal from "./alertModal";
import { createOrder } from "../../actions/OrderAction";

import { SHIP_ADDRESS } from '../../constants/orderConstant';

class PaymentButton extends Component {

    _GrandTotal = 0

    _User = JSON.parse(Cookies.get(AUTH_DETAILS_COOKIE));
    // navigate = useNavigate();


    getGrandTotal = () => {
        this._GrandTotal = (this.props.cartTotal + this.props.deliveryCharges) * 100
        return this._GrandTotal
    }



    // handleSubmit = () => {

    // }

    // const order = {
    //     userId: user.id,
    //     city: shippingAddress.city,
    //     country: shippingAddress.addressLine1,
    //     cartItemDtos: cartItems,
    //     itemPrice: orderInfo.subtotal,
    //     taxPrice: orderInfo.tax,
    //     shippingPrice: deliveryCharges,
    //     totalPrice: cartTotal,
    //     phoneNo: shippingAddrees.addressLine2,
    //     address: shippingInfo.addressLine2,
    //     status: "Processing"
    // };

    handleSubmit = () => {
        let cartItems = [];


            for(const[,product] of Object.entries(this.props.shoppingBag.data)) {
                cartItems.push({
                    stock: product.availableQuantity,
                    productId: product.id,
                    name: product.name,
                    imageUrl: product.imageURL,
                    price: product.price,
                    quantity: this.props.addToCart.productQty[product.id]
                })
            }

          
        
        const order = {
            userId: this._User.userInfo.id,
            city: this.props.shippingAddressForm.values.city,
            country: this.props.shippingAddressForm.values.addressLine1,
            cartItemDtos: cartItems,
            shippingPrice: this.props.deliveryCharges,
            totalPrice: this.props.cartTotal,
            phoneNo: this.props.shippingAddressForm.values.addressLine2,
            address: this.props.shippingAddressForm.values.addressLine2,
            status: "Processing"
        };
        Cookies.set(SHIP_ADDRESS, JSON.stringify(this.props.shippingAddressForm.values), {expires: 7});
        this.props.createOrder(order);
        
       
        // console.log(cartItems);
        // console.log(this._User);
        // console.log(this.props.addToCart);
        // console.log(this.props.cartTotal);
        // console.log(this.props.deliveryCharges);
        // console.log(this.props.shippingOption);
        // console.log(this.props.shoppingBag.data);
    }


    onToken = (token) => {

        this.props.loadingHandler(true)

        this.props.sendPaymentToken({
            ...token,
            amount: this._GrandTotal,
            currency: "USD",
            address: this.props.shippingAddressForm.values,
            addToCart: this.props.addToCart,
            shippingOption: this.props.shippingOption
        })
    }

    renderButton = () => {

        return (
            <Grid container justify="center" style={{ padding: "2rem 0 2rem 0" }}>
                <Grid item lg={9}>
                    <Button variant="contained" size="medium"
                        disabled={this.props.disabled}
                        style={{
                            width: '100%', height: 50, color: 'white',
                            fontWeight: "bold", backgroundColor: "#e01a2b",
                            fontSize: "1rem"

                        }}
                        onClick={this.handleSubmit}
                    >

                        PLACE ORDER
                    </Button>
                </Grid>
            </Grid>
        )
    }

    render() {

        return (
            <>
                {/* <AlertModal title="Payment Error"
                    question="Something went wrong. Please try again later."
                    enable={this.props.paymentResponse.error}
                    timestamp={this.props.paymentResponse.timestamp}
                /> */}

                 {this.props.disabled ?
                    this.renderButton() :
                   this.renderButton()} 
                   
                       
                   
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        cartTotal: state.cartTotalReducer,
        shippingAddressForm: state.form.shippingAddressForm ?
            state.form.shippingAddressForm : null,
        shippingAddress: state.shippingAddressReducer,
        shippingOption: state.shippingOptionReducer,
        addToCart: state.addToCartReducer,
        deliveryCharges: state.deliveryChargesReducer,
        paymentResponse: state.paymentResponseReducer,
        shoppingBag: state.shoppingBagProductReducer
    })
}

export default connect(mapStateToProps, { sendPaymentToken, createOrder })(PaymentButton)