import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearErrors,
  updateProduct,
  getProductDetails,
} from "../../actions/ProductAction";
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import MetaData from "../../layout/MetaData";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import DescriptionIcon from "@material-ui/icons/Description";
import StorageIcon from "@material-ui/icons/Storage";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import SideBar from "./Sidebar";
import { UPDATE_PRODUCT_RESET } from "../../constants/productConstant";
import { useLocation, useNavigate } from "react-router-dom";

const UpdateProduct = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const navitigate = useNavigate();

  const { error, product } = useSelector((state) => state.product);
  const {
    loading,
    error: updateError,
    isUpdated
  } = useSelector((state) => state.changeProduct);

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const [stock, setStock] = useState(0);
  const [image, setImage] = useState("");

 
  const productId = location.pathname.split("/")[2];
  useEffect(() => {
    if (product && product.id !== productId) {
      dispatch(getProductDetails(productId));
    } else {
      setName(product.name);
      setDescription(product.description);
      setPrice(product.price);
      setCategoryId(product.categoryId);
      setStock(product.stock);
      setImage(product.imageUrl);
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Product Updated Successfully");
      navitigate("/admin/products");
      dispatch({ type: UPDATE_PRODUCT_RESET });
    }
  }, [
    dispatch,
    alert,
    error,
    isUpdated,
    productId,
    product,
    updateError,
  ]);

  const updateProductSubmitHandler = (e) => {
    e.preventDefault();

    const newProduct = {
      name: name,
      price: price,
      description: description,
      categoryId: categoryId,
      stock: stock,
      imageUrl: image
    }

    dispatch(updateProduct(productId, newProduct));
  };

  return (
    <Fragment>
      <MetaData title="Create Product" />
      <div className="dashboard">
        <SideBar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={updateProductSubmitHandler}
          >
            <h1>Update Product</h1>
            <div>
              <SpellcheckIcon />
              <input
                type="text"
                placeholder="Product Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <AttachMoneyIcon />
              <input
                type="number"
                placeholder="Price"
                required
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              />
            </div>
            <div>
              <DescriptionIcon />
              <textarea
                placeholder="Product Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>
            {/* <div>
              <AccountTreeIcon />
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Choose Category</option>
                {categories.map((cate) => (
                  <option key={cate} value={cate}>
                      {cate}
                  </option>
                ))}
              </select>
            </div> */}
              <div>
              <AttachMoneyIcon />
              <input
                type="number"
                placeholder="categoryId"
                required
                onChange={(e) => setCategoryId(e.target.value)}
                value={categoryId}
              />
            </div>
            <div>
              <StorageIcon />
              <input 
                type="number"
                placeholder="Stock"
                required
                onChange={(e) => setStock(e.target.value)}
                value={stock}
              />
            </div>
            <div>
                <input 
                  type="text"
                  placeholder="Image Url"
                  required
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                />
            </div>

            <Button
              id="createProductBtn"
              type="submit"
            >
                Create
            </Button>

          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateProduct;