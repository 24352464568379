import React, {useEffect, useState} from "react";
import SignInForm from "./signInForm"
import {Grid, Typography} from "@material-ui/core";
import {Divider} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {Dimmer, Loader} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import {DocumentTitle} from "../../components/Product/documentTitle";
import {RESET_SIGN_IN_ERROR} from "../../constants/http_error_code";

const SignIn = () => {
    const [isLoading, setIsLoading] = useState(false);
    const {isSignedIn, timestamp} = useSelector(state => state.signInReducer);
    const dispatch = useDispatch();

    const setIsLoadingState = () => {
        setIsLoading(true);
    }

    useEffect(() => {
        setIsLoading(false);
    }, [timestamp]);

    useEffect(() => {
        return () => {
            dispatch({
                type: RESET_SIGN_IN_ERROR
            });
        }
    }, []);

    if(isSignedIn !== null && isSignedIn) {
        return null;
    }

    return (
        <Grid container justify="center" style={{paddingTop: "2rem"}}>
            <Grid item container xs={10} sm={5} lg={3} direction="column">
                <DocumentTitle title="Sign In"/>
                <Grid item style={{alignSelf: "center", paddingBottom: "1rem"}}>
                    <h1 style={{fontSize: "2.5rem"}}>Sign In</h1>
                </Grid>

                <SignInForm loadingHandler={setIsLoadingState}/>
{/* 
                <Grid container justify="center">
                    <Grid item style={{width: "100%", padding: "1rem 0"}}>
                        <Divider horizontal>Or</Divider>
                    </Grid>
                </Grid> */}

                {/* <Grid container justify="center">
                    <Grid item xs={7} sm={5}>
                        <GoogleAuthButton/>
                    </Grid>
                </Grid> */}

                <Grid container justify="center">
                    <Typography style={{padding: '2rem 0', fontWeight: 'bold'}} variant="subtitle2">
                        Not on Shoppers yet?
                        <Link to="/signup"> Sign up</Link>
                    </Typography>
                </Grid>

                {isLoading ? <Dimmer active inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer> : null}

            </Grid>
        </Grid>
    )
}

export default SignIn;