import { Fragment } from "react";

import "./style.css";



const Announcement = () => {
  const hahaha = "nfdfd";
  return (
      <Fragment>
        <div className="modelBox">
        <img src="../images/myLove.jpg" width="100%"></img>
        <div className="offer-text">
          <h2>Get 15$ OFF</h2>
          <small>on all beauty products</small>
          <p>Welcome to Shop DDN</p>
          <form>
            <input type="email" placeholder="Email Address"></input>
            <button type="submit">Subscribe</button>
          </form>
        </div>
        <img src="../images/close.jpg" className="close-button"></img>
      </div>
      </Fragment>
  )
};

export default Announcement;