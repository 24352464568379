import React, { Fragment, useEffect, useState } from "react";
import MetaData from "../../layout/MetaData";
import { Typography } from "@material-ui/core";
import SideBar from "./Sidebar";
import {
    getOrderDetail,
    clearErrors,
    updateOrder,
} from "../../actions/OrderAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../layout/Loader";
import { useAlert } from "react-alert";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { Button } from "@material-ui/core";
import { UPDATE_ORDER_RESET } from "../../constants/orderConstant";
import "./processOrder.css";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

const ProcessOrder = () => {
    const { order, error, loading } = useSelector((state) => state.orderDetail);
    const { error: updateError, isUpdated } = useSelector((state) => state.changeOrder);
    const user = JSON.parse(localStorage.getItem('user'));
    const location = useLocation();
    const orderId = location.pathname.split("/")[3];
    

    const updateOrderSubmitHandler = (e) => {
        e.preventDefault();

        const newOrder = {
            status: status,
        }
       dispatch(updateOrder(orderId, newOrder));
    }

    const dispatch = useDispatch();
    const alert = useAlert();

    const [status, setStatus] = useState("");

    useEffect(() => {
        if(error){
            alert.error(error);
            dispatch(clearErrors());
        }
        if(updateError){
            alert.success("Order Updated Successfully");
            dispatch(clearErrors());
        }
        if(isUpdated){
            alert.success("Order Updated Successfully");
            dispatch({ type: UPDATE_ORDER_RESET });
        }

        dispatch(getOrderDetail(orderId));
    }, [dispatch, alert, error, isUpdated, updateError, orderId]);


    return (
        <Fragment>
            <MetaData title="Process Order" />
            <div className="dashboard">
                <SideBar />
                <div className="newProductContainer">
                    {loading ? (
                        <Loader />
                    ) : (
                        <div
                            className="confirmOrderPage"
                            style={{
                                display: order.status === 1 ? "block" : "grid",
                            }}
                        >
                            <div>
                                <div className="confirmShippingArea">
                                    <Typography>Shipping Info</Typography>
                                    <div className="orderDetailsContainerBox">
                                        <div>
                                            <p>Name:</p>
                                            <span>{ user.username}</span>
                                        </div>
                                        <div>
                                            <p>Phone:</p>
                                            <span>
                                               {order.phoneNo}
                                            </span>
                                        </div>
                                        <div>
                                            <p>Address:</p>
                                            <span>
                                              {`${order.address}, ${order.state}, ${order.city}, ${order.country}`}
                                            </span>
                                        </div>
                                    </div>

                                    <Typography>Payment</Typography>
                                    <div className="orderDetailsContainerBox">
                                        <div>
                                            <p
                                                className={
                                                    order &&
                                                    order.paymentStatus === "succeeded"
                                                        ? "greenColor"
                                                        : "redColor"
                                                }
                                            >
                                                {order &&
                                                order.paymentStatus === "succeeded"
                                                    ? "PAID"
                                                    : "NOT PAID"}
                                            </p>
                                        </div>

                                        <div>
                                            <p>Amount:</p>
                                            <span>{order.totalPrice && order.totalPrice}</span>
                                        </div>
                                    </div>

                                    <Typography>Order Status</Typography>
                                    <div className="orderDetailsContainerBox">
                                        <div>
                                          <p
                                            className={
                                                order.status && order.status === "Delivered"
                                                    ? "greenColor"
                                                    : "redColor"
                                            }
                                          >
                                            {order.status && order.status}
                                          </p>
                                        </div>                
                                    </div>
                                </div>
                                <div className="confirmCartItems">
                                   <Typography>Your Cart Items:</Typography>   
                                   <div className="confirmCartItemsContainer">
                                      {order.orderItem &&
                                        order.orderItem.map((item) => (
                                            <div key={item.id}>
                                                <img src={item.imageUrl} />
                                                {/* <NavLink to={`/product/${item.product}`}> */}
                                                    {item.name}
                                                {/* </NavLink> */}
                                                <span>
                                                    {item.quantity} X ₹{item.price} = {" "}
                                                    <b>₹{item.price * item.quantity}</b>
                                                </span>
                                            </div>
                                        ))}      
                                   </div>         
                                </div>
                            </div>
                            <div
                                style={{
                                    display: order.status === "Delivered" ? "none" : "block",
                                }}
                            >   
                                <form
                                    className="updateOrderForm"
                                    onSubmit={updateOrderSubmitHandler}
                                >
                                    <h1>Process Order</h1>
                                    <div>
                                        <AccountTreeIcon />
                                        <select onChange={(e) => setStatus(e.target.value)}>
                                            <option value="">Choose Category</option>
                                            {order.status === "Processing" && (
                                                <option value="Shipped">Shipped</option>
                                            )}

                                            {order.status === "Shipped" && (
                                                <option value="Delivered">Delivered</option>
                                            )}
                                        </select>
                                    </div>

                                    <Button
                                        id="createdProductBtn"
                                        type="submit"
                                        disabled={
                                            loading ? true : false || status === "" ? true : false
                                        }
                                    >
                                        Process
                                    </Button>
                                </form>
                            </div>                   
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
};

export default ProcessOrder;