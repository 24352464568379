import React, { Fragment, useEffect, useState } from "react";
import "./orderDetails.css";

import MetaData from "../layout/MetaData";
import { NavLink } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { clearErrors, getOrderDetail } from "../actions/OrderAction";
import Loader from "../layout/Loader";
import { useAlert } from "react-alert";
import { useLocation } from "react-router-dom";
import {useSelector, useDispatch} from "react-redux"


const OrderDetails = () => {
    const  {order, loading, error} = useSelector((state) => state.orderDetail);
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));
    const location = useLocation();
    const alert = useAlert();
    const orderId = location.pathname.split("/")[2];

    


    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
          }
        dispatch(getOrderDetail(orderId));
    }, [dispatch,orderId]);


    return (
        <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
                   <MetaData title="Order Details" />
                   <div className="orderDetailsPage">
                        <div className="orderDetailsContainer">
                            <Typography component="h1">
                                Order #{order && order.id}
                            </Typography>
                            <Typography>Shipping Info</Typography>
                            <div className="orderDetailsContainerBox">
                                <div>
                                    <p>Name:</p>
                                    <span>{user.username}</span>
                                </div>
                                <div>
                                    <p>Phone:</p>
                                    <span>
                                        {/* {order.shippingInfo && order.shippingInfo.phoneNo} */}
                                    </span>
                                </div>
                                <div>
                                    <p>Address:</p>
                                    <span>
                                        {`${order.line},${order.province}`}
                                    </span>
                                </div>
                            </div>
                            <Typography>Payment</Typography>
                            <div className="orderDetailsContainerBox">
                                <div>
                                    <p
                                        className={
                                            order.paymentInfo &&
                                            order.paymentInfo.status === "succeeded"
                                                ? "greenColor"
                                                : "redColor"
                                        }
                                    >
                                        {order.paymentInfo &&
                                        order.paymentInfo.status === "succeeded"
                                            ? "PAID"
                                            : "NOT PAID"}
                                    </p>
                                </div>
                                <div>
                                    <p>Amount:</p>
                                    <span>{order.totalPrice && order.totalPrice}</span>
                                </div>
                            </div>
                            <Typography>Order Status</Typography>
                            <div className="orderDetailsContainerBox">
                                <div>
                                    <p
                                         className={
                                            order.status && order.status === 1
                                              ? "greenColor"
                                              : "redColor"
                                          }
                                    >
                                        {order.status && order.status}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* <div className="orderDetailsCartItems">
                            <Typography>Order Items:</Typography>
                            <div className="orderDetailsCartItemsContainer">
                                {order.orderItem &&
                                    order.orderItem.map((item) => (
                                        <div key={item.product}>
                                            <img src={item.image} alt="Product" />
                                            <NavLink to={`/product/${item.product}`} >
                                                {item.name}
                                            </NavLink>{" "}
                                            <span>
                                                {item.quantity} X ₹{item.price} ={" "}
                                                <b>₹{item.price * item.quantity}</b>
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        </div> */}
                   </div>
                </Fragment>
      )}
      </Fragment>
    );
};

export default OrderDetails;