export const INTERNAL_SERVER_ERROR_CODE = 500;
export const BAD_REQUEST_ERROR_CODE = 400;


export const HANDLE_SIGN_IN= "HANDLE_SIGN_IN";
export const HANDLE_SIGN_OUT= "HANDLE_SIGN_OUT";
export const HANDLE_SIGN_IN_ERROR= "HANDLE_SIGN_IN_ERROR";
export const RESET_SIGN_IN_ERROR = "RESET_SIGN_IN_ERROR";
export const SET_GOOGLE_AUTH= "SET_GOOGLE_AUTH";
export const HANDLE_GOOGLE_AUTH_SIGN_IN= "HANDLE_GOOGLE_AUTH_SIGN_IN";
export const HANDLE_GOOGLE_AUTH_SIGN_OUT= "HANDLE_GOOGLE_AUTH_SIGN_OUT";
export const HANDLE_SIGN_UP_ERROR= "HANDLE_SIGN_UP_ERROR";
export const RESET_SIGN_UP= "RESET_SIGN_UP";
export const AUTH_DETAILS_COOKIE = "AUTH_DETAILS_COOKIE";
