import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import DropdownSection from "./dropDown";
import { SORT_ATTRIBUTE } from "../../constants/filterConstant";

import { useNavigate, useLocation } from "react-router-dom"


export default function FilterDropdown() {
    const navigate = useNavigate();
    const location = useLocation();
    const sortList = useSelector(state => state.filterAttributesReducer.data ?
        state.filterAttributesReducer.data.sortby : null)
    const selectedSort = useSelector(state => state.selectSortReducer)
    const [sortValue, setSortValue] = useState({id:1, value: ""})

    useEffect(() => {
        if (selectedSort != null) {
            setSortValue(selectedSort)
        }
    }, [selectedSort])

    if (!sortList) {
        return null
    }

    const onChangeHandler = (id, value) => {
      
        setSortValue({id, value})
        let route = location.pathname
        let queryStr = location.search
        if (location.search.search("sortby") === -1) {
            navigate(`${route}${queryStr}::sortby=${id}`)
        } else {
            navigate(`${route}${queryStr.replace(new RegExp(`sortby=[0-9]`), `sortby=${id}`)}`)
        }
    }

   

    return (
        <DropdownSection
            attrList={sortList}
            selectedValue={sortValue}
            appendText="Sort by:"
            title="sortby"
            size="lg"
            onChangeHandler={onChangeHandler}/>
    );
}