import React, {useEffect, useState} from 'react';
import CheckboxList from "./checkboxList";
import {useSelector} from "react-redux";
import CheckboxMoreButton from "./checkBoxMoreButton";
import CheckboxSearchBar from "./checkBoxSearchBar";
import {toggleId} from "../../../helper/toggleId";
import history from "../../../helper/history";
import {updateQueryString} from "../../../helper/updateQueryString";
import { useNavigate, useLocation } from "react-router-dom";

export default function BrandCheckBox() {
    const navitiagate = useNavigate();
    const location = useLocation();
    const TITLE = "Brand";
    const propName = "brands";
    const brandList = useSelector(state => state.filterAttributesReducer.data ?
        state.filterAttributesReducer.data.brands : null);
    const [searchBrandList, setSearchBrandList] = useState(null);
    const selectedBrands = useSelector(state => state.selectedFilterAttributesReducer.brands);
    const [selectedList, setSelectedList] = useState([]);
    const resetFilter = useSelector(state => state.clearFiltersReducer);
    

    useEffect(() => {
        if(selectedBrands.length > 0) {
            setSelectedList(selectedBrands);
        } else {
            setSelectedList([]);
        }
    }, [selectedBrands]);

    useEffect(() => {
        if(resetFilter) {
            if(selectedList.length > 0) {
                setSelectedList([]);
            }
        }
    }, [window.location.search]);

    if(!brandList) {
        return null;
    }

    const getActiveBrandList = () => {
        return searchBrandList ? searchBrandList : brandList;
    }

    const handleSearchListChange = (searchList) => {
        setSearchBrandList(searchList);
    }

    const handleCheckboxChange = (id, value) => {
        const {list, ids} = toggleId(id, value, selectedList);
        setSelectedList(list);
        navitiagate(updateQueryString(location,propName,id,ids));
    }

    return (
        <>
            <CheckboxSearchBar title={TITLE}
                               placeholderText="Search for Brands"
                               checkboxList={brandList}
                               searchListHandler={handleSearchListChange}/>
            <CheckboxList attrList={getActiveBrandList()}
                          title="Brand"
                          maxItems={6}
                          selectedAttrList={selectedList}
                          onChangeHandler={handleCheckboxChange}/>
            <CheckboxMoreButton title={TITLE}
                                propName={propName}
                                checkboxList={brandList}
                                size={9}
                                selectedCheckboxList={selectedList}
                                checkboxChangeHandler={handleCheckboxChange}/>
        </>
    );
  
}