import axios from "axios";
import {CLEAR_ERRORS, DELETE_REVIEWS_FAIL, DELETE_REVIEWS_REQUEST, DELETE_REVIEWS_SUCCESS, GET_ALL_REVIEWS_FAIL, GET_ALL_REVIEWS_REQUEST, GET_ALL_REVIEWS_SUCCESS, GET_REVIEWS_DETAIL_FAIL, GET_REVIEWS_DETAIL_REQUEST, GET_REVIEWS_DETAIL_SUCCESS, NEW_REVIEWS_FAIL, NEW_REVIEWS_REQUEST, NEW_REVIEWS_SUCCESS, UPDATE_REVIEWS_FAIL, UPDATE_REVIEWS_REQUEST, UPDATE_REVIEWS_SUCCESS} from "../constants/reviewConstant";


const API_URL = "https://kaitoshop.io.vn/api/reviews";
// const API_URL = "http://localhost:8083/api/reviews";

export const createReviews = (reviewsData) => async (dispatch) =>  {
    try {
        dispatch({ type: NEW_REVIEWS_REQUEST });

        const {data} = await axios.post(
            API_URL + "/add",
            reviewsData
        );
        
        dispatch({
            type: NEW_REVIEWS_SUCCESS,
            payload: data,
        })
    } catch(error) {
        dispatch({
            type: NEW_REVIEWS_FAIL,
            payload: null
        });
    }
}

export const updateReviews = (reviewId,reviewsData) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_REVIEWS_REQUEST
        });

        const { data } = await axios.put(
            API_URL + "/update/" + reviewId,
            reviewsData
        );

        dispatch({
            type: UPDATE_REVIEWS_SUCCESS,
            payload: data.success,
        });

    } catch(error) {
        dispatch({
            type: UPDATE_REVIEWS_FAIL,
            payload: null
        });
    }
}

export const deleteReviews = (reviewId) => async dispatch => {
    try {
        dispatch({
            type: DELETE_REVIEWS_REQUEST
        });

        const { data } = axios.delete(
            API_URL + "/delete/" + reviewId
        );

        dispatch({
            type: DELETE_REVIEWS_SUCCESS,
            payload: data.success
        })
    } catch(error) {
        dispatch({
            type: DELETE_REVIEWS_FAIL,
            payload: null
        });
    }
}

export const getAllReviews = (params) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_REVIEWS_REQUEST });

        const { data } = axios.get(
            API_URL + "/getAllReviews",
            {params}
        );

        dispatch({
            type: GET_ALL_REVIEWS_SUCCESS,
            payload: data
        })
    } catch(error) {
        dispatch({
            type: GET_ALL_REVIEWS_FAIL,
            payload: null
        })
    }
}

export const getReviewsById = (reviewId) => async dispatch => {
    try {
        dispatch({ type: GET_REVIEWS_DETAIL_REQUEST });

        const { data } = await axios.get(
            API_URL + "/" + reviewId
        );
        console.log(data);
        dispatch({
            type: GET_REVIEWS_DETAIL_SUCCESS,
            payload: data
        })
    } catch(error) {
        dispatch({
            type: GET_REVIEWS_DETAIL_FAIL,
            payload: null
        });
    }
}

export const clearErrors = () => async dispatch => {
    dispatch({
        type: CLEAR_ERRORS
    });
}
