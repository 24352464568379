import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from 'react-redux'
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import {
    getDataViaAPI, setDefaultSearchSuggestion, setAuthDetailsFromCookie,
    signOut
} from "../../actions/CommonAction";
import { LOAD_TABS_DATA } from "../../constants/filterConstant";
import { TABS_DATA_API } from "../../constants/api_constants"
import {
    AppBar, Toolbar, IconButton, Typography
} from '@material-ui/core';
import { ADD_TO_CART, SHOPPERS_PRODUCT_INFO_COOKIE, TABS_API_OBJECT_LEN } from "../../constants/typeConstants"
import useNavBarStyles from "../../styles/navBarStyles";
import TabList from "./tabList";
import Hidden from "@material-ui/core/Hidden";
import BagButton from "./bagButton";
import SearchBar from "./searchbar";
import SideBar from "./sideBar";
import Avatar from '@material-ui/core/Avatar';
import MobileMenu from "./mobileMenu";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { tabsDataReducer } from "../../reducers/tabReducer"
import Cookies from "js-cookie";
import { AUTH_DETAILS_COOKIE } from "../../constants/http_error_code";
import {HTTPError} from "../error/httpError";
import {BadRequest} from "../error/badRequest";


const NavBar = (props) => {
    const classes = useNavBarStyles();
    const navigate = useNavigate();
    const [mobileSearchState, setMobileSearchState] = useState(false);
    const [hamburgerBtnState, setHamburgerBtnState] = useState(false);
    const tabsAPIData = useSelector(state => state.tabsDataReducer);
    const { isSignedIn, tokenId, firstName } = useSelector(state => state.signInReducer);
    const dispatch = useDispatch();

    let authIcon = null;
    let authLabel = null;
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


    /**
     * set the cart from saved Cookie
     */

    const setAddToCartValuesFromCookie = () => {
        let savedProductsFromCookie = Cookies.get(SHOPPERS_PRODUCT_INFO_COOKIE);
        let totalQuantity = 0;
        if (savedProductsFromCookie === null && savedProductsFromCookie === undefined) {
            for (const [, qty] of Object.entries(savedProductsFromCookie.productQty)) {
                totalQuantity += parseInt(qty);

            }

            savedProductsFromCookie.totalQuantity = totalQuantity;

            dispatch({
                type: ADD_TO_CART,
                payload: savedProductsFromCookie
            });
        } 
    }


    useEffect(() => {

        if (isSignedIn === null) {
            let savedAuthDetails = Cookies.get(AUTH_DETAILS_COOKIE);
            if (savedAuthDetails !== null && savedAuthDetails !== undefined) {
                props.setAuthDetailsFromCookie(JSON.parse(savedAuthDetails));
            }
        } 



        if (!tabsAPIData.hasOwnProperty("data")) {
            props.getDataViaAPI(LOAD_TABS_DATA, TABS_DATA_API, null, false);
        }

        setAddToCartValuesFromCookie()

        props.setDefaultSearchSuggestion();

    }, [isSignedIn, tabsDataReducer]);

    if (tabsAPIData.isLoading) {
        return null;
    } else {
        if (tabsAPIData.hasOwnProperty("data")) {
            if (Object.entries(tabsAPIData.data).length !== TABS_API_OBJECT_LEN) {
                return <BadRequest/>;
            }
        } else {
            if (tabsAPIData.hasOwnProperty("statusCode")) {
                props.errorHandler()
                 return <HTTPError statusCode={tabsAPIData.statusCode}/>
              
            }
        }
    }

    if (isSignedIn) {
        let fName;
        if (firstName) {
            fName = firstName;
        } else {
            fName = "S"
        }

        authIcon = <Avatar sizes="small"
            style={{
                width: 20, height: 20,
                backgroundColor: "orange",
                filter: "saturate(5)"
            }}>
            {fName.charAt(0).toUpperCase()}
        </Avatar>
        authLabel = "Sign Out";
    } else {
        authIcon = <AccountCircle />;
        authLabel = "Sign In";

    }

    const changeAuthStatusHandler = () => {
        if (tokenId && isSignedIn) {
            // props.signOut();
            navigate("/profile")
        } else {
            navigate("/signin");
        }
        handleMobileMenuClose();
    }



    const renderIndependentElem = (eventHandler, icon, label, paddingTop) => {
        return (
            <Grid item>
                <Grid container direction="column" alignItems="center"
                    onClick={eventHandler} style={{ cursor: 'pointer' }}>
                    <Grid item style={{ height: 21, width: 21, paddingTop: paddingTop }}>
                        {icon}
                    </Grid>
                    <Grid item style={{ color: "black", fontSize: "0.8rem", fontWeight: 'bold' }}>
                        {label}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const changePageToShoppingBagHandler = () => {
        navigate("/shopping-bag");
        setMobileMoreAnchorEl(null);
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleMobileSearchClose = () => {
        setMobileSearchState(false)
    }

    const handleMobileSearchOpen = () => {
        setMobileSearchState(true)
    }

    const renderMobileSearchInputField = () => {
        if (mobileSearchState) {
            return <SearchBar size="medium" device="mobile" handleClose={handleMobileSearchClose} />
        }
        return (
            <>
                <Grid item>
                    <IconButton onClick={handleMobileSearchOpen}
                        edge="end">
                        <SearchIcon fontSize="large" />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        aria-label="show more"
                        aria-controls={mobileMenuId}
                        aria-haspopup="true"
                        onClick={handleMobileMenuOpen}
                        color="inherit"
                        edge="end">
                        <MoreIcon fontSize="large" />
                    </IconButton>
                </Grid>
            </>
        )
    }

    const handleSidebarOpen = () => {
        setHamburgerBtnState(true)
    }

    const handleSidebarClose = () => {
        setHamburgerBtnState(false)
    }

   

    return (
        <>
            <SideBar open={hamburgerBtnState} closeHandler={handleSidebarClose} />

            <div style={{ paddingBottom: 80 }}>
                <AppBar color="default" className={classes.appBarRoot}>
                    <Toolbar classes={{ root: classes.toolBarRoot }}>
                        <Grid container alignItems="center">
                            <Hidden lgUp>
                                {!mobileSearchState ?
                                    <Grid item>
                                        <IconButton
                                            edge="start"
                                            className={classes.menuButton}
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={handleSidebarOpen}>
                                            <MenuIcon fontSize="large" />
                                        </IconButton>
                                    </Grid> : null}
                            </Hidden>

                            {!mobileSearchState ? <Grid item>
                                <Link to="/">
                                    <Typography className={classes.title}>
                                        ShopDDN
                                    </Typography>
                                </Link>
                            </Grid> : null}

                            <div className={classes.growHalf} />

                            <Hidden mdDown>
                                <Grid item xs={5}>
                                    <TabList />
                                </Grid>

                                <div className={classes.growHalf} />
                            </Hidden>

                            <Hidden xsDown>
                                <Grid item container sm={6} md={7} lg={4}>
                                    <SearchBar size="small" />
                                </Grid>
                            </Hidden>

                            <Hidden smUp>
                                <div className={classes.growHalf} />
                                <div className={classes.growHalf} />
                                {renderMobileSearchInputField()}
                            </Hidden>

                            <Hidden xsDown>
                                <div className={classes.growHalf} />

                                {renderIndependentElem(changeAuthStatusHandler, authIcon, authLabel,
                                    2)}
                                <div className={classes.growQuarter} />

                                {renderIndependentElem(changePageToShoppingBagHandler, <BagButton />,
                                    "Bag", 0)}
                            </Hidden>



                        </Grid>
                    </Toolbar>
                </AppBar>

                <MobileMenu mobileMenuId={mobileMenuId}
                    authIcon={authIcon}
                    authLabel={authLabel}
                    bagBtnHandler={changePageToShoppingBagHandler}
                    mobileMoreAnchorEl={mobileMoreAnchorEl}
                    isMobileMenuOpen={isMobileMenuOpen}
                    handleMobileMenuClose={handleMobileMenuClose}
                />
            </div>
        </>
    );
};

export default connect(null, {
    getDataViaAPI, setDefaultSearchSuggestion, setAuthDetailsFromCookie, signOut
})(NavBar);
