import React, { useEffect } from "react";
import Sidebar from "./Sidebar.js";
import "./dashboard.css";
import { Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { Doughnut, Line, Chart } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import { getProduct } from "../../actions/ProductAction";
import { getAllUsers } from "../../actions/AuthAction";
import MetaData from "../../layout/MetaData";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
  } from 'chart.js';


const DashBoard = () => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
      );
    const dispatch = useDispatch();

    const { products } = useSelector((state) => state.products);

    // const { orders } = useSelector((state) => state.allOrders);

    const { users } = useSelector((state) => state.allUsers);

    let outOfStock = 0;

    products &&
        products.forEach((item) => {
            if (item.stock === 0) {
                outOfStock += 1;
            }
        });

    useEffect(() => {
        dispatch(getProduct());
        dispatch(getAllUsers());
    }, [dispatch]);

    let totalAmount = 0;
    // orders &&
    //     orders.forEach((item) => {
    //         totalAmount += item.totalAmount;
    //     });

    const lineState = {
        labels: ["Initial Amount", "Amount Earned"],
        datasets: [
            {
                label: "TOTAL AMOUNT",
                backgroundColor: ["tomato"],
                hoverBackgroundColor: ["rgb(197, 72, 49)"],
                data: [0, totalAmount],
            },
        ],
    };

    const doughnutState = {
        labels: ["Out of Stock", "InStock"],
        datasets: [
            {
                backgroundColor: ["#00A6B4", "#6800B4"],
                hoverBackgroundColor: ["#4B5000", "#35014F"],
                data: [outOfStock, products.length - outOfStock],
            },
        ],
    };

    return (
        <div className="dashboard">
            <MetaData title="Dashboard - Admin Panel" />
            <Sidebar />

            <div className="dashboardContainer">
                <Typography component="h1">Dashboard</Typography>

                <div className="dashboardSummary">
                    <div>
                        <p>
                            Total Amount <br /> ${totalAmount}
                        </p>
                    </div>

                    <div className="dashboardSummaryBox2">
                        <NavLink to="/admin/products">
                            <p>Product</p>
                            <p>{products && products.length}</p>
                        </NavLink>

                        {/* <NavLink to="/admin/orders">
                            <p>Orders</p>
                            <p>{orders && orders.length}</p>
                        </NavLink> */}

                        <NavLink to="/admin/users">
                            <p>Users</p>
                            <p>{users && users.length}</p>
                        </NavLink>
                    </div>
                </div>

                <div className="lineChart">
                    <Line data={lineState} />
                </div>

                <div className="doughnutChart">
                    <Doughnut data={doughnutState} />
                </div>
            </div>
        </div>
    )
}

export default DashBoard;