import React from 'react'

import {
    StyledLargeDropdown,
    StyledSmallDropdown,
    StyledSmallMenu
} from "../../styles/customStyles";
import "../../styles/commonStyles.css"

import {Menu} from 'semantic-ui-react'

const DropdownSection = props => {

    if (!props.attrList) {
       
        return null
    }

    let optionList = props.attrList.map(({id, type}) => {
      
        return {
        
            text: type,
            value: id
        }
    })
    console.log("OptionList");
    console.log(optionList);
    const handleDropdownChange = (e, {id, value}) => {
       
        props.onChangeHandler(value, optionList[value - 1].text)
    }

    const renderLargeDropdown = () => {
        return (
            <Menu compact>
                <StyledLargeDropdown options={optionList}
                                     simple item
                                     id={`${props.title}-dropdown`}
                                     text={`${props.appendText} ${props.selectedValue.value ? props.selectedValue.value
                                         : optionList ? optionList[0].text : null}`}
                                     onChange={handleDropdownChange}
                                     value={props.selectedValue.id}/>

            </Menu>
        )
    }

    const renderSmallDropdown = () => {
        return (
            <StyledSmallMenu compact>
                <StyledSmallDropdown options={optionList}
                                     simple item
                                     id={`${props.title}-dropdown`}
                                     text={`${props.appendText} ${props.selectedValue.value ? props.selectedValue.value
                                         : optionList ? optionList[0].text : null}`}
                                     onChange={handleDropdownChange}
                                     value={props.selectedValue.id}/>
            </StyledSmallMenu>
        )
    }

   
    return (
        <>
            {props.size && props.size.localeCompare("sm") === 0? renderSmallDropdown(): renderLargeDropdown()}
        </>
    )
}

export default DropdownSection