import Home from "./components/Home/home";

import Shipping from "./pages/Shipping";
import Login from "./pages/Login";
import OrderSuccess from "./pages/OrderSuccess";

import ConfirmOrder from "./pages/ConfirmOrder";

import DashBoard from "./pages/admin/DashBoard";
import ProductListAdmin from "./pages/admin/ProductList";
import NewProduct from "./pages/admin/NewProduct";
import UpdateProduct from "./pages/admin/UpdateProduct";
import UsersList from "./pages/admin/UserList";
import OrderDetails from "./pages/OrderDetails";
import OrderList from "./pages/admin/OrderList";
import ProcessOrder from "./pages/admin/ProcessOrder";
import NavBar from "./components/NavBar/navBar";
import Announcement from "./components/notify/Announcement";


import Checkout from "./components/checkout/checkout";
import { SuccessPayment } from "./components/checkout/SuccessPayment";
import ProductDetails from "./components/detail/ProductDetails";
import MyOrder from "./components/user/MyOrder";



import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { logout } from "./actions/AuthAction";

import SignIn from "./components/signin/SignIn";
import SignUp from "./components/signup/signUp";
import Profile from "./components/user/Profile";




// import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";
import MyCart from "./pages/MyCart";
import PaymentParent from "./pages/PaymentParent";
import Product from "./components/Product/product";
import { TabPanelList } from "./components/NavBar/tabPanelList";
import ShoppingBag from "./components/NavBar/shoppingBag";
const App = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  // useEffect(() => {
  //   WebFont.load({
  //     google: {
  //       families: ["Roboto", "Droid Sans", "Chilanka"],
  //     },
  //   });
  // }, []);


  useEffect(() => {
    // if (currentUser) {
    //   setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
    //   setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    // } else {
    //   setShowModeratorBoard(false);
    //   setShowAdminBoard(false);
    // }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);


  return (

    <BrowserRouter >
      <div className="container mt-3">
        <NavBar />
        <TabPanelList />
       
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route exact path="/notify" element={<Announcement />} /> */}

          <Route path="/cart" element={<MyCart />} />
          <Route exact path="/shipping" element={<Shipping />} />
          <Route exact path="/success" element={<OrderSuccess />} />

          {/* <Route exact path="/navbar" element={<NavBar />} /> */}

          <Route path="/products" element={<Product />} />

          <Route exact path="/order/confirm" element={<ConfirmOrder />} />

          <Route exact path="/admin/dashboard" element={<DashBoard />} />
          <Route exact path="/admin/products" element={<ProductListAdmin />} />
          <Route exact path="/admin/createProduct" element={<NewProduct />} />
          <Route exact path="/admin/product/:id" element={<UpdateProduct />} />
          <Route exact path="/admin/users" element={<UsersList />} />
          <Route exact path="/process/payment" element={<PaymentParent />} />

          <Route exact path="/orders" element={<MyOrder />} />
          <Route exact path="/order/:id" element={<OrderDetails />} />
          <Route exact path="/admin/orders" element={<OrderList />} />
          <Route exact path="/admin/order/:id" element={<ProcessOrder />} />
          <Route path="/products/:details" exact element={<ProductDetails />} />
          <Route path="/checkout" exact element={<Checkout />} />
          <Route path="/products/details/shopping-bag" exact element={<ShoppingBag />} />
          <Route path="/shopping-bag" exact element={<ShoppingBag />} />
          <Route path="/checkout/success-payment" exact element={<SuccessPayment />} />

          <Route path="/profile" exact element={<Profile />} />
          <Route path="/signin" exact element={<SignIn />} />
          <Route path="/signup" exact element={<SignUp />} />

        </Routes>
      </div>
    </BrowserRouter>
  )
};

export default App;