
import { ADD_TO_CART, RESET_ADD_TO_CART } from "../constants/cartConstant";
import {
    LOAD_FILTER_ATTRIBUTES, LOAD_FILTER_PRODUCTS, LOAD_HOME_PAGE, REMOVE_FILTER_ATTRIBUTES,
    SAVE_SORT_LIST, SELECT_PRODUCT_DETAIL
} from "../constants/filterConstant"
import { HANDLE_SIGN_IN, HANDLE_SIGN_IN_ERROR, HANDLE_SIGN_OUT, HANDLE_SIGN_UP_ERROR, RESET_SIGN_IN_ERROR, RESET_SIGN_UP } from "../constants/http_error_code";
import {
    CART_TOTAL, DELIVERY_CHARGES, GET_ALL_CATEGORY_SUCCESS, INITIAL_SHIPPING_ADDRESS_STATE, INITIAL_SHIPPING_OPTION_STATE, LOAD_SHOPPING_BAG_PRODUCTS, PAYMENT_INFO_CONFIRMED, PAYMENT_RESPONSE, PAYMENT_RESPONSE_ERROR, RESET_CART_TOTAL,
    RESET_DELIVERY_CHARGES, RESET_PAYMENT_RESPONSE, RESET_PAYMENT_RESPONSE_ERROR, RESET_SHIPPING_ADDRESS, RESET_SHIPPING_OPTION, RESET_SHOPPING_BAG_PRODUCTS,
    SEARCH_KEYWORD,
    SEARCH_KEYWORD_ERROR,
    SHIPPING_ADDRESS_CONFIRMED,
    SHIPPING_OPTION_CONFIRMED
} from "../constants/typeConstants";
import _ from "lodash";


export const addToCartReducer = (state = { totalQuantity: 0, productQty: {} }, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            return {
                ...state, totalQuantity: action.payload.totalQuantity,
                productQty: action.payload.productQty
            };
        case RESET_ADD_TO_CART:
            return { totalQuantity: 0, productQty: {} }
        default:
            return state;
    }
};

export const filterAttributesReducer = (state = { isLoading: true }, action) => {
    switch (action.type) {
        case LOAD_FILTER_ATTRIBUTES:

            return action.payload;
        case REMOVE_FILTER_ATTRIBUTES:
            return null;
        default:
            return state;
    }
};

export const savedSortedListReducer = (state = null, action) => {
    switch (action.type) {
        case SAVE_SORT_LIST:
            return action.payload
        default:
            return state;
    }
};

export const filterProductsReducer = (state = { isLoading: true }, action) => {
    switch (action.type) {
        case LOAD_FILTER_PRODUCTS:
            return action.payload;
        default:
            return state;
    }
};

export const getAllCategoryReducer = (state = {allCategories: {}}, action) => {
    if (action.type === GET_ALL_CATEGORY_SUCCESS) {
        return {
          ...state,
          allCategories: action.payload,
    
        };
      }
      return state;
}

export const homePageDataReducer = (state = { isLoading: true }, action) => {
    switch (action.type) {
        case LOAD_HOME_PAGE:
            return action.payload;
        default:
            return state;
    }
};

export const selectProductDetailReducer = (state = { isLoading: true }, action) => {
    switch (action.type) {
        case SELECT_PRODUCT_DETAIL:
            return action.payload
        default:
            return state;
    }
}

export const cartTotalReducer = (state = 0, action) => {
    switch (action.type) {
        case CART_TOTAL:
            return action.payload;
        case RESET_CART_TOTAL:
            return 0;
        default:
            return state;
    }
};

export const deliveryChargesReducer = (state = 0, action) => {
    switch (action.type) {
        case DELIVERY_CHARGES:
            return action.payload;
        case RESET_DELIVERY_CHARGES:
            return 0;
        default:
            return state;
    }
}

export const shoppingBagProductReducer = (state = { isLoading: true }, action) => {
    switch (action.type) {
        case LOAD_SHOPPING_BAG_PRODUCTS:
            return action.payload;
        case RESET_SHOPPING_BAG_PRODUCTS:
            return { isLoading: false, data: {} };
        default:
            return state;
    }
}

export const shippingAddressReducer = (state = INITIAL_SHIPPING_ADDRESS_STATE, action) => {
    switch (action.type) {
        case SHIPPING_ADDRESS_CONFIRMED:
            return action.payload;
        case RESET_SHIPPING_ADDRESS:
            return INITIAL_SHIPPING_ADDRESS_STATE;
        default:
            return state;
    }
};

export const paymentResponseReducer = (state = { error: false, errorMsg: null, timestamp: null }, action) => {
    switch (action.type) {
        case PAYMENT_RESPONSE:
            return  action.payload;
        case PAYMENT_RESPONSE_ERROR:
            return {...state, error: true, errorMsg: action.payload.errorMsg, timestamp: Date.now()};
        case RESET_PAYMENT_RESPONSE_ERROR:
            return {...state, error: false, errorMsg: null, timestamp: null};
 
        case RESET_PAYMENT_RESPONSE:
            return {error: false, errorMsg: null, timestamp: null};
        default:
            return state;
    }
}

export const shippingOptionReducer = (state = INITIAL_SHIPPING_OPTION_STATE, action) => {
    switch(action.type) {
        case SHIPPING_OPTION_CONFIRMED:
            return action.payload;
        case RESET_SHIPPING_OPTION:
            return INITIAL_SHIPPING_OPTION_STATE;
        default:
            return state;

    }
}

export const paymentInfoReducer  = (state = {value: null, submitted: false}, action) => {
    switch(action.type) {
        case PAYMENT_INFO_CONFIRMED:
            return action.payload;
        default:
            return state;
    }
}

export const searchKeywordReducer = (state = {error: false, errorMsg: null, data: [{keyword: ' '}]}, action) => {
    switch(action.type) {
        case SEARCH_KEYWORD:
            return {...action.payload, error: false, errorMsg: null};
        case SEARCH_KEYWORD_ERROR:
            return {...state, error: true, errorMsg: "Something went wrong"};
        default: 
            return state;
    }
}


export const signInReducer = (state = {isSignedIn: null, timestamp: null, firstName: null}, action) => {
    switch(action.type) {
        case HANDLE_SIGN_IN:
            return {
                ...state, isSignedIn: true, tokenId: action.payload.jwt,
                errorMsg: null, firstName: action.payload.firstName, timestamp: Date.now()
            };
        case HANDLE_SIGN_IN_ERROR:
            return {
                ...state, isSignedIn: false, errorMsg: action.payload,
                timestamp: Date.now(),
            };
        case RESET_SIGN_IN_ERROR:
            return {
                ...state, errorMsg: null
            }
        case HANDLE_SIGN_OUT:
            _.omit(state, 'tokenId', 'errorMsg');
            return {
                ...state, isSignedIn: false, firstName: null
            };
        default:
            return state;
    }
};


export const signUpReducer = (state = {errorMsg: null, timestamp: null}, action) => {
    switch(action.type) {
        case HANDLE_SIGN_UP_ERROR:
            return {...state, errorMsg: action.payload, timestamp: Date.now()};
        case RESET_SIGN_UP:
            return {account_status: null, errorMsg: null};
        default:
            return state;
    }
};

