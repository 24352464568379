import React from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Payment from "./Payment";





const PaymentParent = () => {
    const stripePublic = "pk_test_51KDshXAKR6Fq84rZm72E2uD6Do1xy4uni2kqstqw5h7nLSUjUy4HELxtBSHJo4fdPR5tAVUgRImM07QhDaL6wq4y00BgdKwcko";
    const stripePromise = loadStripe(stripePublic);

    return (
        <Elements stripe={stripePromise}>
            <Payment />
        </Elements>
    )
}


export default PaymentParent;