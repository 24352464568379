import {HANDLE_TAB_HOVER_EVENT, LOAD_USER_REQUEST, SEARCH_KEYWORD, SEARCH_KEYWORD_ERROR} from "../constants/typeConstants";
import {LOAD_TABS_DATA} from "../constants/filterConstant";


export const tabHoverEventReducer = (state
                                         = {index: false, hover: false, tabColor: "black"}, action) => {
    switch (action.type) {
        case HANDLE_TAB_HOVER_EVENT:
            return action.payload;

        default:
            return state;
    }
};

export const searchKeywordReducer = (state = {error: false, errorMsg: null, data: [{keyword: ''}]}, action) => {
    switch(action.type) {
        case SEARCH_KEYWORD:
            return {...action.payload, error: false, error: false, errorMsg: null};
        case SEARCH_KEYWORD_ERROR:
            return {...state, error: true, error: "Something went wronng"};
        default:
            return state;
    }
};

export const tabsDataReducer = (state = {isLoading : true}, action) => {
    switch(action.type) {
        case LOAD_TABS_DATA:
            return action.payload;
        default:
            return state;
    }
};