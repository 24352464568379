import React, { Fragment, useEffect, useState } from "react";
import "./Shipping.css";
import { useSelector, useDispatch } from "react-redux";
import { saveShippingInfo } from "../actions/CartAction";
import MetaData from "../layout/MetaData";
import PinDropIcon from "@material-ui/icons/PinDrop";
import HomeIcon from "@material-ui/icons/Home";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PublicIcon from "@material-ui/icons/Public";
import PhoneIcon from "@material-ui/icons/Phone";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import { Country, State } from "country-state-city";
import { useAlert } from "react-alert";
import CheckoutSteps from "./CheckoutSteps";
import { useNavigate } from 'react-router-dom';
import axios from "axios";



const Shipping = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { shippingInfo } = useSelector((state) => state.cart);
    const alert = useAlert();
    const [province, setProVince] = useState("");
    const [district, setDistrict] = useState("");
    const [ward, setWard] = useState("");
    const [country, setCountry] = useState(shippingInfo.country);
    const [pinCode, setPinCode] = useState(shippingInfo.pinCode);
    const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo);
    const BASE_URL = "https://provinces.open-api.vn/api/";

    const [provinceArray, setProvinceArray] = useState([]);
    const [districtArray, setDistrictArray] = useState([]);
    const [wardArray, setWardArray] = useState([]);

    const shippingSubmit = (e) => {
        e.preventDefault();
       
        if (phoneNo.length < 10 || phoneNo.length > 10) {
            alert.error("Phone Number should be 10 digits Long");
            return;
        }

        dispatch(
            saveShippingInfo({ province, district, ward, country, pinCode, phoneNo })
        );
        navigate("/order/confirm");
    };

    // const renderData = (array, select) => {
    //     let row = 
    // }

    const fetchDataProvince = () => {
        axios.get(BASE_URL)
            .then(({ data }) => {
                setProvinceArray(data);
            }) .catch((error) => alert("Error Message"));
    }
    const fetchDataDistrict = () => {
        axios.get(BASE_URL + `d/${province.code}` )
            .then(({ data }) => {
                setDistrictArray(data);
            }) .catch((error) => alert("Error Message"));
    }
    const fetchDataWord = () => {
        axios.get(BASE_URL + `w/${district.code}`)
            .then(({ data }) => {
                setWardArray(data);
            }) .catch((error) => alert("Error Message"));
    }
   
    useEffect(() => {
        fetchDataProvince();
        fetchDataDistrict();
        fetchDataWord();
    }, []);

    useEffect(() => {
        if(province !== null) {
            fetchDataDistrict();
            if(district !== null) {
                fetchDataWord()
            }
        }
    }, [province, district])

    console.log(provinceArray);
    console.log(districtArray);

    console.log(wardArray);


    return (
        <Fragment>
            <MetaData title="Shipping Details" />
            <CheckoutSteps activeStep={0} />
            <div className="shippingContainer">
                <div className="shippingBox">
                    <h2 className="shippingHeading">Shipping Details</h2>
                    <form
                        className="shippingForm"
                        encType="multipart/form-data"
                        onSubmit={shippingSubmit}
                    >
                        <div>
                            <HomeIcon />
                            <select
                                required
                                value={provinceArray}
                                onChange={(e) => setProVince(e.target.value)}
                            >
                                <option value="">Tinh</option>
                                {provinceArray &&
                                    provinceArray.map((item) => (
                                        <option key={item.code} value={item.code}>
                                            {item.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>

                        <div>
                            <LocationCityIcon />
                            <select
                                required
                                value={districtArray}
                                onChange={(e) => setProVince(e.target.value)}
                            >
                                <option value="">Quan</option>
                                {districtArray &&
                                    districtArray.map((item) => (
                                        <option key={item.code} value={item.code}>
                                            {item.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>

                        <div>
                            <LocationCityIcon />
                            <select
                                required
                                value={wardArray}
                                onChange={(e) => setWard(e.target.value)}
                            >
                                <option value="">Huyen</option>
                                {wardArray &&
                                    wardArray.map((item) => (
                                        <option key={item.code} value={item.code}>
                                            {item.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>

                        <div>
                            <PinDropIcon />
                            <input
                                type="number"
                                placeholder="Pin Code"
                                required
                                value={pinCode}
                                onChange={(e) => setPinCode(e.target.value)}
                            />
                        </div>

                        <div>
                            <PhoneIcon />
                            <input
                                type="number"
                                placeholder="Phone Number"
                                required
                                value={phoneNo}
                                onChange={(e) => setPhoneNo(e.target.value)}
                                size="10"
                            />
                        </div>

                        {/* <div>
                            <PublicIcon />
                            <select
                                required
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                            >
                                <option value="">Country</option>
                                {Country &&
                                    Country.getAllCountries().map((item) => (
                                        <option key={item.isoCode} value={item.isoCode}>
                                            {item.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div> */}

                        {/* {country && (
                            <div>
                                <TransferWithinAStationIcon />
                                <select
                                    required
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                >
                                    <option value="">State</option>
                                    {State &&
                                        State.getStatesOfCountry(country).map((item) => (
                                            <option key={item.isoCode} value={item.isoCode}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        )} */}
                        <input 
                            type="submit"
                            value="Continue"
                            className="shippingBtn"
                        />
                         {/* disabled={state ? false : true} */}

                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default Shipping;