export const LOAD_TABS_DATA = "LOAD_TABS_DATA";
export const LOAD_FILTER_PRODUCTS = "LOAD_FILTER_PRODUCTS";
export const HOME_ROUTE = '/';
export const PRODUCTS_ROUTE = '/products';
export const CHECKOUT_ROUTE = '/checkout';
export const DETAILS_ROUTE = '/products/details';
export const LOAD_FILTER_ATTRIBUTES = "LOAD_FILTER_ATTRIBUTES";
export const REMOVE_FILTER_ATTRIBUTES = "REMOVE_FILTER_ATTRIBUTES";
export const FILTER_ATTRIBUTES_API = "/filter";
export const PRODUCT_BY_CATEGORY_DATA_API = "/products";



export const INITIAL_SELECTED_FILTER_ATTRIBUTE_STATE = {
    genders: [],
    apparels: [],
    brands: [],
    prices: [],
    oldQuery: null,
    newQuery: null
};

export const INITIAL_PAGINATION_STATE = {
    pageNumber: 1,
    maxProducts: 16,
    isLoadedFromURL: true
}

export const INITIAL_SORT_STATE = {
    id: 1,
    value: null,
    isLoadedFromURL: true
}

export const FILTER_ATTRIBUTES = ["genders", "apparels", "brands", "prices"];

export const ADD_SELECTED_CATEGORY = "ADD_SELECTED_CATEGORY";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
export const LOAD_SELECTED_CATEGORY_FROM_URL = "LOAD_SELECTED_CATEGORY_FROM_URL";
export const REMOVE_SELECTED_CATEGORY = "REMOVE_SELECTED_CATEGORY";
export const RESET_SELECT_PRODUCT_PAGE = "RESET_SELECT_PRODUCT_PAGE";
export const RESET_SELECT_SORT_CATEGORY = "RESET_SELECT_SORT_CATEGORY";
export const RESET_SELECTED_CATEGORY = "RESET_SELECTED_CATEGORY";
export const SELECT_PRODUCT_PAGE = "SELECT_PRODUCT_PAGE";
export const SELECT_SORT_CATEGORY = "SELECT_SORT_CATEGORY";
export const SORT_ATTRIBUTE = "sortby";
export const PAGE_ATTRIBUTE = "page";
export const MAX_PRODUCTS_PER_PAGE = 16;
export const SAVE_SORT_LIST = "SAVE_SORT_LIST";
export const SELECT_PRODUCT_DETAIL = "SELECT_PRODUCT_DETAIL";
export const LOAD_HOME_PAGE = "LOAD_HOME_PAGE";