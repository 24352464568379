import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import "./myOrder.css";
import { useSelector, useDispatch } from "react-redux";


import MetaData from "./MetaData";
import { NavLink } from "react-router-dom";
// import { useAlert } from "react-alert";
import Typography from "@material-ui/core/Typography";

import LaunchIcon from "@material-ui/icons/Launch";
import Cookies from 'js-cookie';
import { AUTH_DETAILS_COOKIE } from "../../constants/http_error_code";
import {myOrders} from "../../actions/OrderAction"


const MyOrder = () => {
    const dispatch = useDispatch();
    const orders = useSelector(state => state.myOrdersReducer);
    const user = JSON.parse(Cookies.get(AUTH_DETAILS_COOKIE));
 
    useEffect(() => {
        dispatch(myOrders(user.userInfo.id));
    },[]);

    const columns = [
        { field: "id", headerName: "Order ID", minwidth: 300, flex: 1 },
        {
            field: "status",
            headerName: "Status",
            minwidth: 150,
            flex: 0.5,
            cellStyle: (params) => {
                return params.row.status === "Processing"
                   ? "red"
                   : "green"
            },
        },
        {
            field: "paymentStatus",
            headerName: "Payment Status",
            minwidth: 150,
            flex: 0.3,
        },
        {
            field: "amount",
            headerName: "Amount",
            type: "number",
            minwidth: 270,
            flex: 0.5
        },
        {
            field: "actions",
            flex: 0.3,
            headerName: "Actions",
            minwidth: 150,
            type: "number",
            sortable: false,
            renderCell: (params) => {
                return (
                    <NavLink to={`/order/${params.getValue(params.row.id, "id")}`}>
                        <LaunchIcon />
                    </NavLink>
                );
            },
        },
    ];

    const rows = [];

    orders.orders &&
        orders.orders.forEach((item, id) => {
            rows.push({
                paymentStatus: item.paymentStatus,
                id: item.orderId,
                status: item.status,
                amount: item.totalPrice
            });
        });

    // useEffect(() => {
    //     dispatch(myOrders(user.id));
    // }, [dispatch]);
        console.log(orders);


    return (
        <Fragment>
            <MetaData title={`${user.userInfo.username} - Orders`} />
          
                <div className="myOrdersPage">
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                        className="myOrdersTable"
                        autoHeight
                    />

                    <Typography id="myOrdersHeading">{user.name}'s Orders</Typography>
                </div>
            
        </Fragment>
    )
}

export default MyOrder;