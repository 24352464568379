
import { combineReducers } from "redux";
import {reducer as formReducer} from "redux-form";
import { loginReducer, allUserReducer, profileReducer } from "./authReducer";
import message from "./message";
import { productReducer, productDetailReducer, newProductReducer, changeProductReducer } from "./productReducer";
import { cartReducer } from "./cartReducer";
import {
  allOrdersReducer,
  myOrdersReducer,
  newOrderReducer,
  orderDetailsReducer,
  changeOrderReducer,
} from "./orderReducer";
import { tabHoverEventReducer, searchKeywordReducer, tabsDataReducer } from "./tabReducer"
import {
  addToCartReducer, filterAttributesReducer, filterProductsReducer,
  savedSortedListReducer, homePageDataReducer, selectProductDetailReducer,
  cartTotalReducer,deliveryChargesReducer,shoppingBagProductReducer,shippingAddressReducer,
  paymentResponseReducer,shippingOptionReducer,paymentInfoReducer,
  getAllCategoryReducer,signInReducer,signUpReducer
} from "./commonReducer";
import { selectedFilterAttributesReducer, clearFiltersReducer, selectSortReducer, selectPageReducer } from "./selectedFilterReducer";
import {getAllReviewsReducer,newReviewReducer} from "./reviewReducer"


const rootReducer = combineReducers({
  auth: loginReducer,
  message,
  products: productReducer,
  product: productDetailReducer,
  cart: cartReducer,
  allUsers: allUserReducer,
  newProduct: newProductReducer,
  changeProduct: changeProductReducer,
  profile: profileReducer,
  orderDetail: orderDetailsReducer,
  newOrder: newOrderReducer,
  allOrders: allOrdersReducer,
  changeOrder: changeOrderReducer,
  myOrdersReducer,
  tabHoverEventReducer,
  searchKeyword: searchKeywordReducer,
  tabsDataReducer: tabsDataReducer,
  addToCartReducer,
  filterAttributesReducer,
  filterProductsReducer,
  savedSortedListReducer,
  selectedFilterAttributesReducer,
  clearFiltersReducer,
  selectSortReducer,
  selectPageReducer,
  homePageDataReducer,
  selectProductDetailReducer,
  cartTotalReducer,
  deliveryChargesReducer,
  shoppingBagProductReducer,
  shippingAddressReducer,
  paymentResponseReducer,
  shippingOptionReducer,
  paymentInfoReducer,
  form: formReducer,
  getAllCategoryReducer: getAllCategoryReducer,
  signInReducer,
  signUpReducer,
  getAllReviewsReducer,
  newReviewReducer
});

export default rootReducer;
