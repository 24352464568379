import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {NavLink} from "react-router-dom";

export default function BreadCrumbsSection(props) {
    const renderLinks = () => {
        
        return props.linkList.splice(0, props.linkList.length-1).map(({name, link}) => {
            if(link.length > 0) {
                return (
                    <NavLink color="inherit" to={link} key={name}>
                        {name}
                    </NavLink>
                )
            }
        })
    }

    

    return (
        <Breadcrumbs aria-label="breadcrumb">
            {renderLinks()}
            <Typography color="textPrimary">{props.linkList[props.linkList.length - 1].name}</Typography>
        </Breadcrumbs>
    );
}