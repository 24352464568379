import React, { useEffect } from "react";
import { Dimmer } from "semantic-ui-react";
import VerticalSlider from "./verticalSlider";
import TopCategoriesAndBrands from "./topCategoriesAndBrands";
import { StyledSegment, StyledDimmer } from "../../styles/customStyles";
import { connect, useSelector } from "react-redux";
import { DocumentTitle } from "../Product/documentTitle";
import { getDataViaAPI } from "../../actions/CommonAction";

import { homePageDataReducer } from "../../reducers/commonReducer";
import HomeMenuIcons from "./homeMenuIcon";
import Hidden from "@material-ui/core/Hidden";
import Spinner from "../Product/spinner";
import { HTTPError } from "../error/httpError";
import { LOAD_HOME_PAGE } from "../../constants/filterConstant";
import { BadRequest } from "../error/badRequest";
import { HOME_PAGE_DATA_API } from "../../constants/api_constants";
import { HOME_PAGE_API_OBJECT_LEN } from "../../constants/api_constants";

import axios from "axios";
import { Grid } from "@material-ui/core";
import { AUTH_DETAILS_COOKIE } from "../../constants/http_error_code";
import Cookies from "js-cookie";
import Announcement from "../../components/notify/Announcement"


const Home = props => {
    const { hover } = useSelector(state => state.tabHoverEventReducer)
    const homeAPIData = useSelector(state => state.homePageDataReducer)


    useEffect(() => {




        if (!homeAPIData.hasOwnProperty("data")) {
            props.getDataViaAPI(LOAD_HOME_PAGE, HOME_PAGE_DATA_API, null, false);
        }


    }, [homePageDataReducer]);

    // we will be showing spinner till we get the data via API
    if (homeAPIData.isLoading) {

        return <Spinner textComponent={
            <Grid container direction="column" spacing={1} style={{
                alignItems: "center", fontSize: "1.1rem", fontWeight: "bold"
            }}>
                <Grid item>
                    Please wait! This will take 1-2 minutes to load page for the first time.
                </Grid>
                <Grid item>
                    The backend service on heroku was in sleep mode and now its started.
                </Grid>
                <Grid item>
                    It will sleep again if the backend service is inactive for 30 minutes.
                </Grid>
            </Grid>} />
    } else {

        // check if we got the data from the API
        if (homeAPIData.hasOwnProperty("data")
            && Object.entries(homeAPIData.data).length !== HOME_PAGE_API_OBJECT_LEN) {



            // if we can't get the data then the front end
            // didn't use the API correctly.
            return <BadRequest />

            // if statusCode exist in the object then
            // we are sure that something went wrong at server side while
            // fetching the API
        } else if (homeAPIData.hasOwnProperty('statusCode')) {

            return <HTTPError statusCode={homeAPIData.statusCode} />
        }
    }


    return (

        <Dimmer.Dimmable as={StyledSegment} dimmed={hover}>

            <DocumentTitle title="Online Shopping for Women, Men, Kids Fashion & Lifestyle - Shoppers" />

            <Hidden only={['xs', 'lg']}>
                <Announcement />
            </Hidden>
            <Hidden only={['xs', 'lg']}>
                <HomeMenuIcons />
            </Hidden>
            <Hidden only={['xs']}>
                <VerticalSlider />
            </Hidden>
            <TopCategoriesAndBrands />
            <StyledDimmer active={hover} />
        </Dimmer.Dimmable>
    )
}

export default connect(null, { getDataViaAPI })(Home);