import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from "./store";
import { Provider } from "react-redux";
import { positions, transitions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import '../src/styles/index.css';
import '../src/styles/library/swiper/swiper.css';
import '../src/styles/library/swiper/swiper.min.css';
import 'semantic-ui-css/semantic.min.css';
import ErrorBoundary from "./ErrorBoundary";




const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
  transition: transitions.SCALE,
};
ReactDOM.render(
  <Provider store={store}>
     <AlertProvider template={AlertTemplate} {...options}>
      <App />
      </AlertProvider>
  </Provider >,
  document.getElementById("root")
);