import {useEffect} from "react";

import {addToCartReducer} from "../reducers/commonReducer";
import Cookies from "js-cookie";

import {CART_TOTAL_COOKIE,CART_TOTAL} from "../constants/typeConstants";
import {useDispatch} from "react-redux";

export function useCartTotal () {
    const dispatch = useDispatch();

    useEffect(() => {
        let cartTotal = Cookies.get(CART_TOTAL_COOKIE);
        if(cartTotal) {
            cartTotal = JSON.parse(cartTotal);
            dispatch({
                type: CART_TOTAL,
                payload: parseInt(cartTotal),
            })
        }
    },[addToCartReducer]);
}